import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Button, Form } from 'antd';
import { replyCreate } from '@state/actions';
import IconSend from '@bundle/assets/post/icon-send.svg';
import strings from '@resources/localization';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { TuringoEditor } from '@components/form/Editor/TuringoEditor';
import SVG from '@components/assets/SVG';
import { PostContext } from '@components/content/posts/Post';
import useCommunity from '@hooks/useCommunity';
const ReplyFieldComponent = (props, ref) => {
    var _a, _b;
    const { p_pk, b_pk, c_pk } = props;
    const { cm_pk } = useCommunity();
    const { b_pk: br_pk, p_pk: pr_pk } = useParams();
    const scope = pr_pk ? 'detail' : 'all';
    const isFeed = !br_pk;
    const commentState = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.items[p_pk]) === null || _b === void 0 ? void 0 : _b.childrens.items[c_pk].childrens.createState; });
    const { mask } = useContext(PostContext);
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [mentions, setMentions] = useState([]);
    const childRef = useRef();
    useImperativeHandle(ref, () => ({
        getFocus() {
            childRef.current.focus();
        },
        replyMention(target) {
            childRef.current.replyMention(target);
        },
    }));
    const cleanForm = () => {
        if (childRef && childRef.current) {
            childRef.current.resetEditor();
        }
        form.setFieldsValue({
            content: '',
        });
        setButtonState(false);
    };
    const [buttonState, setButtonState] = useState(false);
    const onChange = (e) => {
        if (e) {
            setButtonState(true);
        }
        else {
            setButtonState(false);
        }
    };
    const submit = (values) => {
        var _a, _b;
        const { content } = values;
        void dispatch(replyCreate({
            urlParams: { cm_pk, b_pk, c_pk },
            bodyParams: Object.assign(Object.assign({}, (currentEntity.publicKey != ((_a = mask.get) === null || _a === void 0 ? void 0 : _a.publicKey) && { mask: (_b = mask.get) === null || _b === void 0 ? void 0 : _b.publicKey })), { parents: [p_pk], content: content.trim(), mentions }),
            extraParams: {
                p_pk: p_pk,
                scope: scope,
                feed: isFeed,
            },
            options: {
                alerts: {
                    success: strings.alerts.comment.create.success,
                    error: strings.alerts.comment.create.failure,
                },
                then: {
                    action: cleanForm,
                },
            },
        }));
    };
    return (_jsxs("div", { style: { display: 'flex', flexDirection: 'row', alignItems: 'start' }, children: [_jsx(AvatarTuringo, { size: 16, style: { height: 40, width: 40, marginRight: 8, lineHeight: '40px', minWidth: 40 }, name: (_a = mask === null || mask === void 0 ? void 0 : mask.get) === null || _a === void 0 ? void 0 : _a.name, avatar: (_b = mask === null || mask === void 0 ? void 0 : mask.get) === null || _b === void 0 ? void 0 : _b.avatarUrl }), _jsx(Form, { form: form, onFinish: submit, layout: 'vertical', style: { flexGrow: 1 }, children: _jsx(Form.Item, { name: ['content'], style: { marginBottom: 0 }, children: _jsx(TuringoEditor, { ref: childRef, placeholder: strings.screens.boards.discussion.comment.placeholder, onChange: onChange, onMentionsChange: setMentions }) }) }), buttonState && (_jsx(Button, { type: 'primary', shape: "circle", style: { marginLeft: 8 }, icon: _jsx(SVG, { style: { width: '16px', height: '16px', marginTop: 5, marginLeft: 2 }, content: IconSend }), onClick: form.submit, loading: commentState === null || commentState === void 0 ? void 0 : commentState.loading, disabled: !buttonState }, 'send'))] }));
};
export const ReplyField = forwardRef(ReplyFieldComponent);
