import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Tooltip } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppSelector } from '@hooks/useStore';
export const PostKindSelection = (props) => {
    const { action, iconPark, disabled, title, tooltip, highlightColor, backgroundColor, selected, compact, firstSelection, showText = true, modal = true, showTooltip = true, } = props;
    const inputProps = {};
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    if (disabled || selected)
        inputProps.visible = false;
    const Component = (_jsxs("div", { onClick: disabled || selected ? null : action, style: {
            fontSize: isMobile ? 16 : 14,
            cursor: disabled || selected ? 'default' : 'pointer',
            color: disabled ? (isDark ? '#595959' : '#BFBFBF') : highlightColor,
        }, children: [_jsx(Avatar, { style: {
                    background: disabled ? "var(--neutral-3)" : backgroundColor,
                    height: !isMobile || !compact ? 32 : 44,
                    width: !isMobile || !compact ? 32 : 44,
                    display: !isMobile || compact ? 'inline-flex' : 'flex',
                    marginRight: (!modal && !compact) || firstSelection ? 8 : !isMobile ? 4 : 0,
                    marginLeft: compact && !modal ? 8 : 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                }, icon: iconPark ? (_jsx(props.iconPark, { style: { position: 'flex', alignItems: 'center' }, theme: "outline", size: isMobile ? 24 : 18, fill: disabled ? (isDark ? '#595959' : '#D9D9D9') : highlightColor })) : (_jsx(props.iconAnt, { style: { fontSize: isMobile ? 20 : 16, color: disabled ? (isDark ? '#595959' : '#D9D9D9') : highlightColor } })) }), (!isMobile || (compact && showText)) && title, firstSelection && tooltip] }));
    if (showTooltip && !disabled && !selected) {
        return (_jsx(Tooltip, Object.assign({}, inputProps, { title: tooltip, children: Component })));
    }
    else {
        return Component;
    }
};
