import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { ConceptSharing, DegreeHat, HamburgerButton, PeopleTopCard } from '@icon-park/react';
import { Button, Row, Col, Drawer, Menu, Affix, Typography, Layout } from 'antd';
import ImageLogoIsotype from '@bundle/assets/branding/isotype.svg';
import ImageLogoBlack from '@bundle/assets/branding/logo-letterblack.svg';
import ImageLogoWhite from '@bundle/assets/branding/logo-letterwhite.svg';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import style from './Header.scss';
import SVG from '@components/assets/SVG';
import { UserMenu } from './components/user_menu/UserMenu';
import { NotificationList } from '@components/content/notifications/NotificationList';
import { ExpandableButton } from '@components/buttons/expandable_button/ExpandableButton';
import { NewSideBar } from './components/sidebar/NewSideBar';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { Router } from '@Router';
import { DemoInfo } from '@screens/landing/components/DemoInfo/DemoInfo';
import { API } from '@api/API';
import { MessagesList } from '@components/content/messages/MessagesList';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { getCurrentCommunity } from '@util/community/getCurrent';
const { Header: HeaderAntd } = Layout;
const Header = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { border = true } = props;
    const authString = strings.screens.auth;
    const isPublic = useAppSelector((state) => !state.auth.authToken);
    const history = useHistory();
    const location = useLocation();
    const isBlocked = useAppSelector((state) => state.auth.authValidation == 'blocked');
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const cm_pk = getCurrentCommunity();
    const isChatScreen = (_a = location.pathname.split('/')[2]) === null || _a === void 0 ? void 0 : _a.startsWith('messages');
    const isOnboardingScreen = (cm_pk && ((_b = location.pathname.split('/')[1]) === null || _b === void 0 ? void 0 : _b.startsWith('onboarding'))) || (!cm_pk && ((_c = location.pathname.split('/')[2]) === null || _c === void 0 ? void 0 : _c.startsWith('onboarding')));
    const hideAuthButtons = location.pathname.includes('/saas') ||
        location.pathname.includes('/saas/form') ||
        location.pathname.includes('/login') ||
        location.pathname.includes('/exit') ||
        location.pathname.includes('/signup') ||
        location.pathname.startsWith('/billing') ||
        location.pathname.includes('/join') ||
        isOnboardingScreen;
    const isLandingB2B = location.pathname == '/';
    const trademarkLogo = location.pathname == '/' ||
        location.pathname.startsWith('/turingo') ||
        location.pathname.startsWith('/aboutUs') ||
        location.pathname.startsWith('/help') ||
        location.pathname.startsWith('/terms') ||
        location.pathname.startsWith('/privacy') ||
        (location.pathname.startsWith('/login') && !cm_pk) ||
        (location.pathname.startsWith('/signup') && !cm_pk) ||
        location.pathname.startsWith('/billing') ||
        location.pathname.startsWith('/cert') ||
        location.pathname.startsWith('/recover');
    const trademarkLogoAlways = location.pathname.startsWith('/saas') || location.pathname.startsWith('/billing') || location.pathname.startsWith('/cert');
    const collapsed = useAppSelector((state) => state.ui.collapsed);
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    const breakpoints = useBreakpoints();
    const isTuringoScreen = breakpoints.turingo;
    const [visible, setVisible] = useState(false);
    const isMobile = breakpoints.isMobile;
    const onClick = () => setVisible(false);
    const menuItems2 = [
        {
            label: (_jsx(Link, { to: {
                    pathname: '/courses',
                }, children: strings.navigation.sidebar.courses })),
            key: 'menu-item-1',
            style: { display: 'flex', alignItems: 'center' },
            onClick: onClick,
            icon: _jsx(DegreeHat, { size: "20", style: { fontSize: 20 } }),
        },
        {
            label: (_jsx(Link, { to: {
                    pathname: '/talks',
                }, children: strings.navigation.sidebar.forums })),
            key: 'menu-item-2',
            style: { display: 'flex', alignItems: 'center' },
            onClick: onClick,
            icon: _jsx(PeopleTopCard, { size: "20", style: { fontSize: 20 } }),
        },
        {
            label: (_jsx(Link, { to: {
                    pathname: '/stories',
                }, children: strings.navigation.sidebar.stories })),
            key: 'menu-item-3',
            style: { display: 'flex', alignItems: 'center' },
            onClick: onClick,
            icon: _jsx(ConceptSharing, { size: "20", style: { fontSize: 20 } }),
        },
    ];
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const community = useAppSelector((state) => { var _a; return (_a = state.community.items[currentCommunity]) === null || _a === void 0 ? void 0 : _a.item; });
    const dispatch = useAppDispatch();
    const openLogoutModal = () => {
        setVisible(false);
        dispatch(modalAction({ modal: 'logout', state: true }));
        void API.analyticsModal({ bodyParams: { modal: 'modal.main.logout', state: 'open' } });
    };
    return (_jsx(Affix, { offsetTop: 0, style: { zIndex: 10 }, children: _jsx(HeaderAntd, { style: Object.assign({ display: 'flex', borderBottom: '1px solid var(--border)', justifyContent: 'center', flexDirection: 'row', padding: 0, width: '100%' }, (!border && { boxShadow: 'none' })), children: _jsxs(Row, { align: "middle", justify: "space-between", style: {
                    width: '100%',
                    maxWidth: isLogged && !trademarkLogoAlways ? '100%' : 1308,
                    flexFlow: 'row',
                    padding: 12,
                    paddingLeft: isLogged ? 16 : 24,
                    paddingRight: isLogged ? 16 : 24,
                }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, children: [!isPublic && isMobile && !hideAuthButtons && !isBlocked && !props.error && _jsx(NewSideBar, {}), _jsxs("div", { style: {
                                    width: community && (isLogged || !trademarkLogo) ? 40 : collapsed && (isLogged || !trademarkLogo) ? 35 : 148,
                                    height: community && (isLogged || !trademarkLogo) ? 40 : collapsed && (isLogged || !trademarkLogo) ? 35 : 32,
                                    display: community ? 'flex' : 'unset',
                                    alignContent: 'center',
                                }, children: [isMobile && (!community || trademarkLogo) && (!isLogged || trademarkLogoAlways) && (_jsx(SVG, { className: style.logo, onClick: () => history.push('/'), style: { width: '32px', height: '32px', display: 'block' }, content: ImageLogoIsotype })), !isMobile && (!community || trademarkLogo) && (!isLogged || trademarkLogoAlways) && (_jsx(SVG, { className: style.logo, onClick: () => history.push('/'), style: { width: '148px', height: '32px', display: 'block' }, content: theme == 'dark' ? ImageLogoWhite : ImageLogoBlack })), community && (isLogged || !trademarkLogo) && (_jsx(AvatarTuringo, { onClick: () => {
                                            history.push(Router.Path.community_home({ cm_pk: community.publicKey }));
                                        }, avatar: community.logoUrl, color: (_e = (_d = community.ui) === null || _d === void 0 ? void 0 : _d.style) === null || _e === void 0 ? void 0 : _e.color, size: 16, width: 40, height: 40, style: {
                                            border: ' 1px solid var(--hover)',
                                            lineHeight: '40px',
                                            cursor: 'pointer',
                                        }, name: community.name }, community.publicKey))] }), community && (isLogged || !trademarkLogo) && !isMobile && (_jsx(Typography.Paragraph, { onClick: () => {
                                    !community ? history.push('/') : history.push(Router.Path.community_home({ cm_pk: community.publicKey }));
                                }, strong: true, style: { marginBottom: 0, fontSize: 18, marginLeft: 8 }, children: community.name }))] }), _jsxs(Col, { style: { display: 'flex', alignItems: 'center' }, children: [isPublic && isLandingB2B && !hideAuthButtons && !isMobile && (_jsxs(_Fragment, { children: [_jsx(Link, { onClick: () => {
                                            void API.analyticsAction({ bodyParams: { action: 'btn.login', sender: 'main.nav.header.direct' } });
                                        }, to: Router.Path.login, children: _jsx(Button, { style: { marginRight: 16 }, children: authString.login.action }) }), _jsx(LinkButton, { externalHref: 'https://calendly.com/turingo/demo-request', onClick: () => {
                                            void API.analyticsAction({ bodyParams: { action: 'btn.demo', sender: 'main.nav.header.direct' } });
                                        }, type: "primary", children: strings.screens.landingb2b.action })] })), isPublic && !isLandingB2B && !hideAuthButtons && !isMobile && (_jsxs(_Fragment, { children: [_jsx(Link, { to: Router.Path.root, style: { padding: '16px 8px', marginRight: 16 }, children: strings.general.create_your_community }), _jsx(Link, { onClick: () => {
                                            void API.analyticsAction({ bodyParams: { action: 'btn.login', sender: 'main.nav.header.direct' } });
                                        }, to: Router.Path.login, children: _jsx(Button, { style: { marginRight: 16 }, children: authString.login.action }) })] })), isPublic && isMobile && !hideAuthButtons && (_jsxs(_Fragment, { children: [_jsx("div", { onClick: () => setVisible(true), style: { lineHeight: '8px' }, children: _jsx(HamburgerButton, { className: isDark ? style.menuDark : style.menuWhite, style: { lineHeight: '8px' }, theme: "outline", size: "24" }) }), _jsxs(Drawer, { bodyStyle: { padding: 0 }, title: null, width: breakpoints.xs || breakpoints.sm || breakpoints.xxs ? '100%' : 408, placement: "right", closable: true, onClose: () => setVisible(false), open: visible, children: [!isLandingB2B && (_jsxs("div", { style: { padding: '0 16px' }, children: [_jsx("p", { style: { fontWeight: 500, fontSize: 30, lineHeight: '40px' }, children: strings.screens.landing.drawer }), _jsx(Menu, { mode: "vertical", selectedKeys: [], items: menuItems2 })] })), isLandingB2B && (_jsx("div", { style: { padding: 16 }, children: _jsx(DemoInfo, { compact: true }) })), _jsxs("div", { style: {
                                                    padding: 16,
                                                    height: !isLandingB2B ? 'calc(100% - 284px)' : 'calc(100% - 446px)',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-end',
                                                    textAlign: 'center',
                                                }, children: [isLandingB2B && (_jsxs(_Fragment, { children: [_jsx(LinkButton, { block: true, style: { marginBottom: 8 }, externalHref: 'https://calendly.com/turingo/demo-request', onClick: () => {
                                                                    setVisible(false);
                                                                    void API.analyticsAction({ bodyParams: { action: 'btn.demo', sender: 'main.nav.header.direct' } });
                                                                }, type: "primary", children: strings.screens.landingb2b.action }), _jsx(Link, { to: {
                                                                    pathname: '/login',
                                                                }, onClick: () => {
                                                                    onClick();
                                                                    void API.analyticsAction({ bodyParams: { action: 'btn.login', sender: 'main.nav.drawer' } });
                                                                }, children: _jsx(ExpandableButton, { children: authString.login.action }) })] })), !isLandingB2B && (_jsxs(_Fragment, { children: [_jsx(Link, { to: {
                                                                    pathname: '/login',
                                                                }, onClick: () => {
                                                                    onClick();
                                                                    void API.analyticsAction({ bodyParams: { action: 'btn.login', sender: 'main.nav.drawer' } });
                                                                }, children: _jsx(ExpandableButton, { style: { marginBottom: 8 }, children: authString.login.action }) }), _jsx(Link, { style: { marginTop: 26, marginBottom: 18 }, to: {
                                                                    pathname: '/',
                                                                }, onClick: onClick, children: strings.general.create_your_community })] }))] })] })] })), !isPublic && !hideAuthButtons && !isBlocked && props.error != 'auth.error' && ((_g = (_f = community === null || community === void 0 ? void 0 : community.plugins) === null || _f === void 0 ? void 0 : _f.chat) === null || _g === void 0 ? void 0 : _g.state) == 'active' && (_jsx(MessagesList, {})), !isPublic && !hideAuthButtons && !isBlocked && props.error != 'auth.error' && community && _jsx(NotificationList, {}), !isPublic && !hideAuthButtons && !isBlocked && props.error != 'auth.error' && _jsx(UserMenu, {}), isOnboardingScreen && (_jsx(Button, { type: "text", onClick: openLogoutModal, children: strings.navigation.usermenu.options.logout }))] })] }) }) }));
};
export { Header };
