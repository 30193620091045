import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FormNameItem } from '@components/form/FormNameItem/FormNameItem';
import { FooterModal } from '@components/modals/components/FooterModal';
import strings from '@resources/localization';
import { userEdit } from '@state/actions';
import { Alert, Form, Switch } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import style from './UserModal.scss';
import { buildUserEditRequest } from '@components/form/Builders/UserEditRequestBuilder';
import useBreakpoints from '@hooks/useBreakpoints';
import { Check, Close } from '@icon-park/react';
import { TuringoUpload } from '@components/form/TuringoUpload/TuringoUpload';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import { addCategoryIdToProperties, getOrderedProperties } from '@screens/community/admin_panel/screens/profiles/Utils';
import { CustomFormItem } from './CustomFormItem';
import { formatAsset } from '@api/requests/AsyncRequests';
import useCommunity from '@hooks/useCommunity';
require('dayjs/locale/es');
require('dayjs/locale/en');
export const optionsFromSegment = (subsector) => Object.keys(subsector).map((key) => ({
    label: subsector[key].name,
    options: subsector[key].segments.map((segment) => ({
        label: segment.name,
        value: segment.publicKey,
    })),
}));
export function processObject(obj, communityProperties) {
    const result = {};
    for (const key in obj) {
        const p = communityProperties.find((p) => p.id == key);
        if ((p === null || p === void 0 ? void 0 : p.type) == 'array:opt:multi' || (p === null || p === void 0 ? void 0 : p.type) == 'array:opt:single') {
            result[key] = obj[key].value.split(',');
        }
        else if ((p === null || p === void 0 ? void 0 : p.type) == 'geo:city:id') {
            result[key] = {
                key: `city-${obj[key].value.id}`,
                label: `${obj[key].value.name}, ${obj[key].value.countryName}`,
                value: `${obj[key].value.id}`,
            };
        }
        else if ((p === null || p === void 0 ? void 0 : p.type) == 'integer') {
            result[key] = parseInt(obj[key].value);
        }
        else if ((p === null || p === void 0 ? void 0 : p.type) == 'boolean') {
            result[key] = obj[key].value == 'true';
        }
        else {
            result[key] = obj[key].value;
        }
    }
    return result;
}
export function getTitleByValue(array, id) {
    const item = array.find((item) => item.value === id.toLowerCase());
    return item ? item.label : null;
}
const UserModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { open, setVisibility, schema } = props;
    const { cm_pk } = useCommunity();
    const { u_pk } = useParams();
    const dispatch = useAppDispatch();
    const language = useAppSelector((state) => state.ui.language);
    dayjs.locale(language);
    const [formInstance] = Form.useForm();
    const entity = useAppSelector((state) => state.user.items[u_pk]);
    const [hiddenEditProfile, setEditProfileHidden] = useState(false);
    const [avatarError, setAvatarError] = useState({ message: undefined, new: false });
    const [hideNumber, setHideNumber] = useState(((_b = (_a = entity === null || entity === void 0 ? void 0 : entity.item) === null || _a === void 0 ? void 0 : _a.privacy) === null || _b === void 0 ? void 0 : _b.phone) || false);
    const cancelEditProfile = () => {
        setVisibility(false);
    };
    const breakpoints = useBreakpoints();
    const communityProperties = getOrderedProperties(addCategoryIdToProperties(schema.categories));
    const editProfile = (values) => {
        const { bannerUrl, avatarUrl, memberProperties } = values;
        for (const key in memberProperties) {
            communityProperties.map((property) => {
                if (property.id == key) {
                    if (property.type == 'geo:city:id') {
                        if (memberProperties[key]) {
                            memberProperties[key] = parseInt(memberProperties[key].value);
                        }
                        else {
                            memberProperties[key] = null;
                        }
                    }
                    if (property.type == 'array:opt:single') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = [memberProperties[key]];
                        }
                    }
                    if (property.type == 'integer') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = parseInt(memberProperties[key]);
                        }
                    }
                    if (property.type == 'boolean') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = memberProperties[key] == 'true';
                        }
                    }
                }
            });
        }
        const { body: bodyParams } = buildUserEditRequest(values, entity.item);
        void dispatch(userEdit({
            urlParams: {
                cm_pk: cm_pk,
            },
            bodyParams: Object.assign(Object.assign({}, bodyParams), { privacyPhone: hideNumber, memberProperties }),
            extraParams: {
                u_pk: entity.item.publicKey,
            },
            uploads: {
                assets: [...formatAsset(bannerUrl, 'banner', 'banner'), ...formatAsset(avatarUrl, 'avatar', 'avatar')],
            },
            options: {
                then: { action: cancelEditProfile },
                alerts: {
                    success: strings.alerts.user.edit.success,
                    error: strings.alerts.user.edit.failure,
                },
            },
        }));
    };
    return (_jsx(TuringoModal, { open: open, title: strings.screens.profile.editModal.title, onOk: formInstance.submit, onCancel: cancelEditProfile, width: 662, hidden: hiddenEditProfile, footer: [
            _jsx(FooterModal, { primary: {
                    action: formInstance.submit,
                    customText: strings.screens.profile.editModal.actions.accept,
                    loading: entity.state.editing,
                }, secondary: {
                    action: cancelEditProfile,
                    customText: strings.screens.profile.editModal.actions.cancel,
                } }, 'footer_modal'),
        ], children: _jsx("div", { className: 'scrollStyle', style: { overflow: 'auto' }, children: _jsxs(Form, { form: formInstance, onFinish: editProfile, scrollToFirstError: {
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                }, initialValues: Object.assign(Object.assign(Object.assign(Object.assign({}, (entity.item.name && {
                    name: entity.item.name,
                })), (entity.item.memberProperties && {
                    memberProperties: processObject((_c = entity === null || entity === void 0 ? void 0 : entity.item) === null || _c === void 0 ? void 0 : _c.memberProperties, communityProperties),
                })), { bannerUrl: ((_d = entity === null || entity === void 0 ? void 0 : entity.item) === null || _d === void 0 ? void 0 : _d.bannerUrl) ? [{ preview: (_e = entity === null || entity === void 0 ? void 0 : entity.item) === null || _e === void 0 ? void 0 : _e.bannerUrl }] : undefined, avatarUrl: ((_f = entity === null || entity === void 0 ? void 0 : entity.item) === null || _f === void 0 ? void 0 : _f.avatarUrl) ? [{ preview: (_g = entity === null || entity === void 0 ? void 0 : entity.item) === null || _g === void 0 ? void 0 : _g.avatarUrl }] : undefined }), (entity.item.visibility && {
                    visibility: entity.item.visibility == 'public' ? false : true,
                })), labelCol: { span: 24 }, wrapperCol: { span: 24 }, layout: "vertical", children: [_jsxs("div", { className: style.imgContainer, style: { marginBottom: breakpoints.xxs || breakpoints.xs ? 70 : 90 }, children: [_jsx(TuringoUpload, { strongLabel: false, label: strings.general.image.plural, isEditing: false, name: 'bannerUrl', rules: {
                                    maxFiles: 1,
                                    maxSize: 10,
                                }, type: 'image', style: { marginBottom: 0 }, uploadArea: {
                                    showDescription: true,
                                    style: {
                                        height: 252,
                                    },
                                }, editOptions: {
                                    aspect_ratio: '5:2',
                                    active: true,
                                }, setError: setAvatarError }), _jsx(TuringoUpload, { setError: setAvatarError, uploadArea: {
                                    btnShape: 'circle',
                                    showDescription: false,
                                    style: {
                                        border: '4px solid var(--background)',
                                        borderRadius: 100,
                                        height: breakpoints.xxs || breakpoints.xs ? 116 : 160,
                                        width: breakpoints.xxs || breakpoints.xs ? 116 : 160,
                                    },
                                }, fileOptions: {
                                    style: {
                                        border: '4px solid var(--background)',
                                        borderRadius: 100,
                                        height: breakpoints.xxs || breakpoints.xs ? 116 : 160,
                                        width: breakpoints.xxs || breakpoints.xs ? 116 : 160,
                                    },
                                }, editOptions: {
                                    aspect_ratio: '1:1',
                                    active: true,
                                }, rules: {
                                    maxFiles: 1,
                                    maxSize: 10,
                                }, style: { position: 'absolute', bottom: breakpoints.xxs || breakpoints.xs ? -80 : -100, left: 24, width: 160, zIndex: 2 }, name: 'avatarUrl', type: 'image' })] }), (avatarError === null || avatarError === void 0 ? void 0 : avatarError.message) && (_jsx(Alert, { showIcon: true, type: "error", message: avatarError.message, style: {
                            borderRadius: 4,
                            marginBottom: 16,
                        } })), _jsx(FormNameItem, { name: 'name', label: strings.screens.profile.editModal.name.label, validations: ['required', 'format', 'long', 'short'] }), communityProperties
                        .sort((a, b) => Number(b.highlight) - Number(a.highlight))
                        .map((property) => {
                        return _jsx(CustomFormItem, { property: property, entity: entity.item }, property.id);
                    }), _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24 }, children: [_jsxs("div", { style: { fontSize: breakpoints.isMobile ? 16 : 14 }, children: [_jsx(LockOutlined, { style: { fontSize: 18, marginRight: 8 } }), strings.screens.profile.editModal.private_account] }), _jsx(Form.Item, { name: "visibility", style: { marginBottom: 0 }, children: _jsx(Switch, { defaultChecked: entity.item.visibility == 'private' ? true : false, checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }) }) })] })] }) }) }));
};
export { UserModal };
