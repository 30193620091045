import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useHistory } from 'react-router';
import strings from '@resources/localization';
import { ArrowLeftIcon } from '@components/icons/ArrowLeftIcon';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
const GoBackButton = (props) => {
    const { showText = true, onClick, style, type = 'text', block, size } = props;
    const history = useHistory();
    const goBack = () => {
        if (onClick) {
            onClick();
        }
        else {
            history.goBack();
        }
    };
    return (_jsx(TuringoButton, { ariaLabel: 'Go back', size: size, shape: showText ? 'standard' : 'square', block: block, type: type, onClick: goBack, icon: _jsx(ArrowLeftIcon, {}), style: Object.assign({}, style), children: showText && _jsx("span", { children: strings.screens.auth.signup.goBack }) }));
};
export { GoBackButton };
