import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
import { Progress } from 'antd';
import Text from 'antd/lib/typography/Text';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
import { progressBitmapFromString } from '@model/progress/ProgressBitmap';
import { ProgressBar } from '../entity/entity_player/progress/ProgressBar';
import { CloseSmall } from '@icon-park/react';
import strings from '@resources/localization';
const CourseProgress = (props) => {
    var _a;
    const { progress, title, description, isEvaluation, isLesson } = props;
    const hasQuiz = Object.values(progress).find((p) => p.kind == 'quiz');
    const { b_pk } = useParams();
    const lessons = useAppSelector((state) => { var _a; return (_a = state.posts.all[b_pk]) === null || _a === void 0 ? void 0 : _a.items; });
    return (_jsx("div", { children: _jsxs("div", { style: { display: 'grid', gap: 16 }, children: [_jsx(InformationModalBody, { kind: 'warning', title: hasQuiz
                        ? hasQuiz.done
                            ? strings.screens.evaluation.rejected.title
                            : isEvaluation
                                ? strings.screens.evaluation.to_done_evaluation.title
                                : strings.screens.evaluation.to_done_certificate.title
                        : title, description: hasQuiz
                        ? hasQuiz.done
                            ? strings.screens.evaluation.rejected.description
                            : isEvaluation
                                ? strings.screens.evaluation.to_done_evaluation.description
                                : !isLesson
                                    ? strings.screens.evaluation.to_done_certificate.description
                                    : ''
                        : description }), _jsx("div", { children: (_a = Object.keys(progress)) === null || _a === void 0 ? void 0 : _a.map((pk, index) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        const pgrs = progress[pk];
                        if (pgrs.kind == 'quiz') {
                            return (_jsxs("div", { children: [_jsx(Text, { children: `${index + 1} - ${((_b = (_a = lessons[pk].item) === null || _a === void 0 ? void 0 : _a.lesson) === null || _b === void 0 ? void 0 : _b.title) || ((_d = (_c = lessons[pk].item) === null || _c === void 0 ? void 0 : _c.quiz) === null || _d === void 0 ? void 0 : _d.title) || strings.screens.evaluation.title}` }), _jsxs("div", { style: { display: 'flex', marginTop: 7, alignItems: 'center', marginBottom: 16 }, children: [_jsx(Progress, { percent: pgrs.done ? 100 : 0, status: pgrs.done ? (pgrs.approved ? 'success' : 'exception') : 'exception', showInfo: false }), _jsx("span", { style: { minWidth: 30, fontSize: 12, marginLeft: 8, color: '#8C8C8C' }, children: pgrs.approved ? `${Math.round(pgrs.done ? 100 : 0)}%` : _jsx(CloseSmall, { theme: "outline", size: "16", fill: "#8C8C8C" }) })] })] }, index));
                        }
                        else {
                            return (_jsxs("div", { children: [_jsx(Text, { children: `${index + 1} - ${(_j = (_g = (_f = (_e = lessons[pk].item) === null || _e === void 0 ? void 0 : _e.lesson) === null || _f === void 0 ? void 0 : _f.title) !== null && _g !== void 0 ? _g : (_h = lessons[pk].item) === null || _h === void 0 ? void 0 : _h.title) !== null && _j !== void 0 ? _j : 'Untitled'}` }), _jsxs("div", { style: { display: 'flex', marginTop: 7, alignItems: 'center', marginBottom: 16 }, children: [_jsx(ProgressBar, { fullfillment: Math.round((pgrs.fulfillment >= 0.8 ? 1 : pgrs.fulfillment) * 100), progressBitmap: progressBitmapFromString(pgrs.watchedSections, pgrs.duration).bitmap }), _jsxs("span", { style: { minWidth: 30, fontSize: 12, marginLeft: 8, color: '#8C8C8C' }, children: [Math.round((pgrs.fulfillment >= 0.8 ? 1 : pgrs.fulfillment) * 100), "%"] })] })] }, index));
                        }
                    }) })] }) }));
};
export { CourseProgress };
