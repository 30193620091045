import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Icon from '@ant-design/icons';
export const CatalogueItem14 = (props) => {
    return (_jsx(Icon, Object.assign({ "aria-hidden": "true", component: () => {
            if (props.filled) {
                return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.11512 6.53458C1.21748 6.33975 1.3756 6.19229 1.55996 6.10207L11.553 1.10557C11.8345 0.964809 12.1659 0.964809 12.4474 1.10557L22.4398 6.10175C22.6245 6.19194 22.7828 6.33951 22.8853 6.53458C22.8933 6.54976 22.9009 6.56515 22.9081 6.58073C22.9738 6.72277 23.0033 6.87279 23.0002 7.02009V17C23.0002 17.3787 22.7862 17.725 22.4474 17.8944L12.4649 22.8857C12.334 22.9545 12.186 22.9952 12.0289 22.9996C12.0194 22.9999 12.0098 23 12.0002 23C11.982 23 11.9637 22.9995 11.9455 22.9985C11.7981 22.9906 11.6592 22.9507 11.5355 22.8857L1.553 17.8944C1.21422 17.725 1.00022 17.3787 1.00022 17V7.02011C0.997131 6.8728 1.02665 6.72277 1.09235 6.58073C1.09955 6.56515 1.10714 6.54976 1.11512 6.53458ZM17.0004 5.61815L19.7641 7.00001L12.0002 10.882L9.23651 9.50011L17.0004 5.61815ZM14.7644 4.5001L12.0002 3.11802L4.2363 7.00001L7.00041 8.38206L14.7644 4.5001ZM6.53177 10.3838L3.00022 8.61803V16.3819L11.0002 20.3819V12.618L6.57298 10.4044C6.5591 10.3979 6.54536 10.391 6.53177 10.3838Z" }) }));
            }
            else {
                return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.11512 6.53458C1.21748 6.33975 1.3756 6.19229 1.55996 6.10207L11.553 1.10557C11.8345 0.964809 12.1659 0.964809 12.4474 1.10557L22.4398 6.10175C22.6245 6.19194 22.7828 6.33951 22.8853 6.53458C22.8933 6.54976 22.9009 6.56515 22.9081 6.58073C22.9738 6.72277 23.0033 6.87279 23.0002 7.02009V17C23.0002 17.3787 22.7862 17.725 22.4474 17.8944L12.4649 22.8857C12.334 22.9545 12.186 22.9952 12.0289 22.9996C12.0194 22.9999 12.0098 23 12.0002 23C11.982 23 11.9637 22.9995 11.9455 22.9985C11.7981 22.9906 11.6592 22.9507 11.5355 22.8857L1.553 17.8944C1.21422 17.725 1.00022 17.3787 1.00022 17V7.02011C0.997131 6.8728 1.02665 6.72277 1.09235 6.58073C1.09955 6.56515 1.10714 6.54976 1.11512 6.53458ZM17.0004 5.61815L19.7641 7.00001L12.0002 10.882L9.23651 9.50011L17.0004 5.61815ZM14.7644 4.5001L12.0002 3.11802L4.2363 7.00001L7.00041 8.38206L14.7644 4.5001ZM6.53177 10.3838L3.00022 8.61803V16.3819L11.0002 20.3819V12.618L6.57298 10.4044C6.5591 10.3979 6.54536 10.391 6.53177 10.3838ZM13.0002 20.3819V12.618L21.0002 8.61803V16.3819L13.0002 20.3819Z" }) }));
            }
        } }, props)));
};
