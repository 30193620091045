import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { Button, Divider, Dropdown } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { PostContext } from '../../Post';
import { MoreOne } from '@icon-park/react';
import Title from 'antd/lib/typography/Title';
import { completeLesson } from '@state/actions';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { CatalogueItemModal } from '@screens/community/admin_panel/components/catalogue_item_modal/CatalogueItemModal';
import { OutsideLinkIcon } from '@components/icons/OutsideLinkIcon';
import { EditOutlined } from '@ant-design/icons';
import { ensureHttp } from '@util/string/Functions';
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton';
const PostTitle = (props) => {
    var _a, _b, _c, _d, _e;
    const { cm_pk, b_pk, p_pk, isPanelAdmin, post } = useContext(PostContext);
    const kind = (_a = post === null || post === void 0 ? void 0 : post.board) === null || _a === void 0 ? void 0 : _a.kind;
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const isAdmin = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.privilige) == 'superadmin';
    const breakpoints = useBreakpoints();
    const isSmall = breakpoints.isSmall;
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const dispatch = useAppDispatch();
    const completeLessonAction = () => {
        void dispatch(completeLesson({
            urlParams: {
                cm_pk,
                b_pk,
                p_pk,
            },
            extraParams: {
                scope: 'detail',
            },
        }));
    };
    const items = [
        {
            label: 'Completar lección',
            key: '0',
            onClick: completeLessonAction,
        },
    ];
    if (!post)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { children: [_jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', padding: '16px 24px' }, children: [showEdit && (_jsx(CatalogueItemModal, { extra: () => {
                            setShowDelete(true);
                            setShowEdit(undefined);
                        }, action: "edit", open: showEdit, target: post, onCancel: () => setShowEdit(false) })), showDelete && _jsx(CatalogueItemModal, { action: "delete", open: showDelete, target: post, onCancel: () => setShowDelete(false) }), _jsx("div", { style: isSmall
                            ? {
                                display: 'grid',
                                gridRowGap: '8px',
                                gridTemplateColumns: '1fr',
                            }
                            : {
                                display: 'flex',
                                justifyContent: 'space-between',
                            }, children: _jsxs("span", { style: !isSmall ? { flex: '1 1 200px' } : {}, children: [post.kind != 'quiz' && kind != 'story' && kind != 'catalog' && !((cm_pk == 'alena' || cm_pk == 'usm') && kind == 'forum') && (_jsxs("div", { children: [(_d = (_c = (_b = strings.screens.boards[kind]) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.singular, " ", (post === null || post === void 0 ? void 0 : post.contentSequence) + 1] })), post.kind == 'quiz' && _jsx("div", { children: strings.screens.evaluation.title }), _jsx("div", { children: _jsx(Title, { level: 4, style: { wordWrap: 'break-word', marginBottom: 0 }, children: ((_e = post === null || post === void 0 ? void 0 : post.lesson) === null || _e === void 0 ? void 0 : _e.title) || (post === null || post === void 0 ? void 0 : post.title) }) })] }) }), isAdmin && kind == 'course' && (_jsx(Dropdown, { menu: { items }, trigger: ['click'], children: _jsx(Button, { "aria-label": 'More options', type: "default", icon: _jsx(MoreOne, { size: "14", style: { display: 'flex', justifyContent: 'center' } }), style: { height: 38, width: 38 } }) })), isPanelAdmin && kind == 'catalog' && (_jsx(Button, { icon: _jsx(EditOutlined, { "aria-hidden": "true" }), onClick: () => {
                            setShowEdit(true);
                        }, type: "default", children: !breakpoints.isMobile && strings.general.edit }))] }), post.url && _jsx(Divider, { style: { margin: 0 } }), post.url && (_jsx("div", { style: { padding: '16px 24px' }, children: _jsx(TuringoButton, { icon: _jsx(OutsideLinkIcon, {}), block: true, onClick: () => window.open(ensureHttp(post.url), '_blank').focus(), children: strings.screens.admin.catalogues.items.goToSite }) }))] }));
};
export { PostTitle };
