import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Avatar from 'antd/es/avatar';
import { Link } from 'react-router-dom';
import { AvatarTuringo } from '@components/avatar/Avatar';
import dayjs from 'dayjs';
import { Colors } from '@util/color/Colors';
import { ChartHistogramTwo, DegreeHat, Report, ViewList } from '@icon-park/react';
import strings from '@resources/localization';
import style from './Notification.scss';
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import useBreakpoints from '@hooks/useBreakpoints';
import { createElement } from 'react';
import { CatalogueIcons } from '@components/form/IconSelector/IconSelector';
import { useAppSelector } from '@hooks/useStore';
import { getCurrentCommunity } from '@util/community/getCurrent';
export const AvatarIcon = (props) => {
    const { backgroundColor, color, iconPark, iconAnt, iconCustom, iconCatalog, size } = props;
    return (_jsx(Avatar, { style: {
            background: backgroundColor,
            height: size == 'normal' ? 40 : 32,
            width: size == 'normal' ? 40 : 32,
            minWidth: size == 'normal' ? 40 : 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: `${size == 'normal' ? 40 : 32}px`,
        }, icon: iconPark ? (_jsx(props.iconPark, { theme: "outline", size: size == 'normal' ? 23 : 18, fill: color })) : iconAnt ? (_jsx(props.iconAnt, { style: { fontSize: 18, color: color } })) : iconCatalog ? (createElement(iconCatalog, {
            style: { margin: 8, color: color },
        })) : (iconCustom) }));
};
const NotificationRules = {
    'usr.community.invited': {
        hasAvatar: true,
    },
    'usr.community.join': {
        hasAvatar: true,
    },
    'usr.community.post': {
        hasAvatar: true,
    },
    'usr.post.comment': {
        hasAvatar: true,
    },
    'usr.comment.reply': {
        hasAvatar: true,
    },
    'usr.comment.colateral': {
        hasAvatar: true,
    },
    'usr.post.like': {
        hasAvatar: true,
    },
    'usr.comment.like': {
        hasAvatar: true,
    },
    'usr.post.mention': {
        hasAvatar: true,
    },
    'admin.community.post': {
        hasAvatar: true,
    },
    'usr.comment.mention': {
        hasAvatar: true,
    },
    'usr.poll.vote': {
        hasAvatar: true,
    },
    'usr.classroom.complete': {
        hasAvatar: false,
    },
    'sys.poll.expired': {
        hasAvatar: false,
        icon: ChartHistogramTwo,
        iconKind: 'iconpark',
        backgroundColor: Colors('VOLCANO_BACKGROUND'),
        color: Colors('VOLCANO_HIGHLIGHT'),
    },
    'sys.classroom.remind': {
        hasAvatar: false,
    },
    'sys.account.validation': {
        hasAvatar: false,
    },
    'mkt.classroom.launch': {
        hasAvatar: false,
    },
    'mkt.classroom.discount': {
        hasAvatar: false,
    },
    'usr.post.report': {
        hasAvatar: false,
        icon: Report,
        iconKind: 'iconpark',
        backgroundColor: Colors('VOLCANO_BACKGROUND'),
        color: Colors('VOLCANO_HIGHLIGHT'),
    },
    'com.team.added': {
        hasAvatar: false,
        icon: InfoCircleOutlined,
        iconKind: 'ant',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.community.invited': {
        hasAvatar: false,
        icon: InfoCircleOutlined,
        iconKind: 'ant',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.community.post': {
        hasAvatar: true,
    },
    'com.course.rate': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.course.quiz.approved': {
        hasAvatar: false,
        icon: ViewList,
        iconKind: 'iconpark',
        backgroundColor: 'var(--magenta-2)',
        color: 'var(--magenta-8)',
    },
    'com.course.quiz.approved.with.result.and.criteria': {
        hasAvatar: false,
        icon: ViewList,
        iconKind: 'iconpark',
        backgroundColor: 'var(--magenta-2)',
        color: 'var(--magenta-8)',
    },
    'com.course.quiz.approved.no.criteria': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.course.merit': {
        hasAvatar: false,
        icon: ViewList,
        iconKind: 'iconpark',
        backgroundColor: 'var(--magenta-2)',
        color: 'var(--magenta-8)',
    },
    'com.course.comment': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.course.remind': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.catalog.comment': {
        hasAvatar: false,
        iconKind: 'catalog',
        backgroundColor: 'var(--polar-green-2)',
        color: 'var(--polar-green-8)',
    },
    'com.admin.added': {
        hasAvatar: true,
    },
    'com.post.pinned.mandatory': {
        hasAvatar: true,
    },
    'com.post.pinned.normal': {
        hasAvatar: true,
    },
    'com.event.added': {
        hasAvatar: false,
        icon: CalendarOutlined,
        iconKind: 'ant',
        backgroundColor: Colors('VOLCANO_BACKGROUND'),
        color: Colors('VOLCANO_HIGHLIGHT'),
    },
    'com.event.start': {
        hasAvatar: false,
        icon: CalendarOutlined,
        iconKind: 'ant',
        backgroundColor: Colors('VOLCANO_BACKGROUND'),
        color: Colors('VOLCANO_HIGHLIGHT'),
    },
    'com.event.remind': {
        hasAvatar: false,
        icon: CalendarOutlined,
        iconKind: 'ant',
        backgroundColor: Colors('VOLCANO_BACKGROUND'),
        color: Colors('VOLCANO_HIGHLIGHT'),
    },
    'sys.convert.finished': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('PURPLE_BACKGROUND'),
        color: Colors('PURPLE_HIGHLIGHT'),
    },
    'sys.poke': {
        hasAvatar: false,
    },
};
export const NotificationItem = (props) => {
    const { item, compact, history, dispatch, language, onClose, isPopup } = props;
    const { sender, predicate, state, dateFirstOcurred, dateLastOcurred, publicKey, count } = item;
    const kind = item.kind;
    const senderAvatar = sender != 'protected' && sender != 'system' ? sender.avatarUrl : undefined;
    const senderName = sender != 'protected' && sender != 'system' ? sender.name : 'SYSTEM';
    const senderPublicKey = sender != 'protected' && sender != 'system' ? sender.publicKey : 'SYSTEM';
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const openNotification = () => {
        if (onClose)
            onClose();
    };
    const buildAvatar = (size) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        if ((_a = NotificationRules[kind]) === null || _a === void 0 ? void 0 : _a.hasAvatar) {
            return (_jsx(AvatarTuringo, { themeForced: 'light', height: size == 'normal' ? 40 : 32, width: size == 'normal' ? 40 : 32, size: 16, avatar: senderAvatar, name: senderName, style: { lineHeight: `${size == 'normal' ? 40 : 32}px` } }));
        }
        else if (((_b = NotificationRules[kind]) === null || _b === void 0 ? void 0 : _b.iconKind) == 'catalog') {
            const catalog = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.community.items[predicate.communityPk]) === null || _a === void 0 ? void 0 : _a.boards.detail[predicate === null || predicate === void 0 ? void 0 : predicate.boardPk]) === null || _b === void 0 ? void 0 : _b.item; });
            return (_jsx(AvatarIcon, { size: size, backgroundColor: (_c = NotificationRules[kind]) === null || _c === void 0 ? void 0 : _c.backgroundColor, color: (_d = NotificationRules[kind]) === null || _d === void 0 ? void 0 : _d.color, iconCatalog: CatalogueIcons[(_f = (_e = catalog.ui) === null || _e === void 0 ? void 0 : _e.menu) === null || _f === void 0 ? void 0 : _f.icon] }));
        }
        else if ((_g = NotificationRules[kind]) === null || _g === void 0 ? void 0 : _g.icon) {
            return (_jsx(AvatarIcon, { size: size, backgroundColor: (_h = NotificationRules[kind]) === null || _h === void 0 ? void 0 : _h.backgroundColor, color: (_j = NotificationRules[kind]) === null || _j === void 0 ? void 0 : _j.color, iconCustom: ((_k = NotificationRules[kind]) === null || _k === void 0 ? void 0 : _k.iconKind) == 'custom' ? (_l = NotificationRules[kind]) === null || _l === void 0 ? void 0 : _l.icon : undefined, iconPark: ((_m = NotificationRules[kind]) === null || _m === void 0 ? void 0 : _m.iconKind) == 'iconpark' ? (_o = NotificationRules[kind]) === null || _o === void 0 ? void 0 : _o.icon : undefined, iconAnt: ((_p = NotificationRules[kind]) === null || _p === void 0 ? void 0 : _p.iconKind) == 'ant' ? (_q = NotificationRules[kind]) === null || _q === void 0 ? void 0 : _q.icon : undefined }));
        }
        else {
            return _jsx(_Fragment, {});
        }
    };
    const getKey = (count) => {
        if (count == 1)
            return 'default';
        if (count == 2)
            return 'one';
        if (count > 2)
            return 'many';
    };
    const notificationStyle = {
        fontFamily: 'Roboto',
        fontDisplay: 'swap',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: isMobile ? 16 : 14,
        lineHeight: '22px',
        marginRight: isPopup ? 20 : 0,
    };
    const buildDescription = (username, name, kind, count) => {
        switch (kind) {
            case 'usr.post.like':
                return (_jsxs("span", { style: notificationStyle, children: [language == 'es' && 'A ', " ", _jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)] }));
            case 'usr.comment.like':
                return (_jsxs("span", { style: notificationStyle, children: [language == 'es' && 'A ', " ", _jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)] }));
            case 'usr.post.comment':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)] }));
            case 'usr.comment.reply':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)] }));
            case 'usr.comment.mention':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)] }));
            case 'usr.comment.colateral':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), 'comentó en la publicación de', " ", _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'usr.post.mention':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)] }));
            case 'usr.post.report':
                return _jsx("span", { style: notificationStyle, children: strings.notifications.kind[kind].default });
            case 'usr.poll.vote':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)] }));
            case 'usr.community.post':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)] }));
            case 'admin.community.post':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name + ' ' }), strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1), _jsx("span", { style: { fontWeight: 500 }, children: ' ' + predicate.name })] }));
            case 'com.team.added':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.community.invited':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.community.post':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name }), " ", strings.notifications.kind[kind].default, ' ', _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.admin.added':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name }), " ", strings.notifications.kind[kind].default] }));
            case 'com.course.rate':
                return (_jsxs("span", { style: notificationStyle, children: [strings.formatString(strings.notifications.kind[kind][getKey(count)], _jsx("span", { children: count })), ' ', _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.course.quiz.approved':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.course.quiz.approved.with.result.and.criteria':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.course.quiz.approved.no.criteria':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.course.merit':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.course.comment':
                return (_jsxs("span", { style: notificationStyle, children: [strings.formatString(strings.notifications.kind[kind][getKey(count)], _jsx("span", { children: count })), ' ', _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.course.remind':
                return (_jsx("span", { style: notificationStyle, children: strings.formatString(strings.notifications.kind[kind].default, _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })) }));
            case 'com.catalog.comment':
                return (_jsx("span", { style: notificationStyle, children: strings.formatString(strings.notifications.kind[kind][getKey(count)], _jsx("span", { style: { fontWeight: 500 }, children: predicate.name }), _jsx("span", { style: { fontWeight: 500 }, children: predicate.boardName }), count > 2 ? _jsx("span", { children: count }) : _jsx(_Fragment, {})) }));
            case 'com.post.pinned.mandatory':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name }), " ", strings.notifications.kind[kind].default] }));
            case 'com.post.pinned.normal':
                return (_jsxs("span", { style: notificationStyle, children: [_jsx("span", { style: { fontWeight: 500 }, children: name }), " ", strings.notifications.kind[kind].default] }));
            case 'com.event.added':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })] }));
            case 'com.event.start':
                return (_jsx("span", { style: notificationStyle, children: strings.formatString(strings.notifications.kind[kind].default, _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })) }));
            case 'com.event.remind':
                return (_jsx("span", { style: notificationStyle, children: strings.formatString(strings.notifications.kind[kind].default, _jsx("span", { style: { fontWeight: 500 }, children: predicate.name })) }));
            case 'sys.convert.finished':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsxs("span", { style: { fontWeight: 500 }, children: [predicate.name, " "] }), strings.notifications.kind[kind].one] }));
            case 'sys.poll.expired':
                return (_jsxs("span", { style: notificationStyle, children: [strings.notifications.kind[kind].default, " ", _jsxs("span", { style: { fontWeight: 500 }, children: [strings.notifications.kind[kind].action, " "] })] }));
            default:
                return 'Under construction';
        }
    };
    const buildOnClick = () => {
        const cm_pk = getCurrentCommunity();
        switch (kind) {
            case 'usr.post.like':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'usr.comment.like':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'usr.post.comment':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'com.post.pinned.mandatory':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'com.post.pinned.normal':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'admin.community.post':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'usr.poll.vote':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'usr.comment.reply':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'usr.comment.colateral':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'usr.post.mention':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'usr.comment.mention':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'usr.community.post':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'com.community.post':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'com.course.rate':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/courses/${predicate.boardPk}`;
            case 'com.course.comment':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/courses/${predicate.boardPk}/${predicate.publicKey}`;
            case 'com.course.remind':
                return `/${predicate.communityPk}/courses/${predicate.boardPk}`;
            case 'com.catalog.comment':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/catalogue/${predicate.boardPk}/${predicate.publicKey}`;
            case 'com.event.added':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/event/${predicate.eventPk}`;
            case 'com.event.start':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/event/${predicate.eventPk}`;
            case 'com.event.remind':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/event/${predicate.eventPk}`;
            case 'sys.convert.finished':
                return `${!cm_pk ? `/${predicate === null || predicate === void 0 ? void 0 : predicate.communityPk}` : ''}/settings/courses/${predicate === null || predicate === void 0 ? void 0 : predicate.boardPk}/${predicate === null || predicate === void 0 ? void 0 : predicate.publicKey}`;
            case 'sys.poll.expired':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/spaces/${predicate.boardPk}/${predicate.publicKey}`;
            case 'com.course.quiz.approved':
                return `/cert/${predicate.publicKey}`;
            case 'com.course.quiz.approved.with.result.and.criteria':
                return `/cert/${predicate.publicKey}`;
            case 'com.course.quiz.approved.no.criteria':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}/courses/${predicate.boardPk}`;
            case 'com.course.merit':
                return `/cert/${predicate.publicKey}`;
            case 'com.team.added':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}`;
            case 'com.community.invited':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}`;
            case 'com.admin.added':
                return `${!cm_pk ? `/${predicate.communityPk}` : ''}`;
            default:
                return '';
        }
    };
    if (compact) {
        return (_jsxs("div", { onClick: buildOnClick, style: { width: '100%', display: 'flex', alignItems: 'center', padding: 16 }, children: [buildAvatar('small'), _jsx("div", { style: { display: 'flex', flexDirection: 'column', marginLeft: 8 }, children: buildDescription(senderPublicKey, senderName, kind, count) })] }));
    }
    else {
        return (_jsx(Link, { to: buildOnClick, onClick: openNotification, children: _jsxs("div", { className: style.notification, style: Object.assign({ width: '100%', display: 'flex', alignItems: 'center', padding: 16, justifyContent: 'space-between', border: `1px solid var(--border)`, borderRadius: 4, color: 'var(--neutral-13)' }, (state == 'new' && { backgroundColor: Colors('POSTED_POLL_BACKGROUND') })), children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [buildAvatar('normal'), _jsxs("div", { style: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: 16,
                                }, children: [buildDescription(senderPublicKey, senderName, kind, count), _jsxs("span", { style: { fontSize: isMobile ? 14 : 12, color: state != 'new' ? 'var(--neutral-7)' : 'var(--text)' }, children: [' ', dayjs(dateLastOcurred).locale(language).fromNow()] })] })] }), state == 'new' && (_jsx("div", { style: { background: 'var(--ant-primary-color-hover)', borderRadius: 100, height: 8, width: 8, minWidth: 8, marginLeft: 16 } }))] }) }));
    }
};
