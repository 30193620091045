import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClassNames from '@util/style/ClassNames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import style from './AlanEditor.scss';
import { CheckOne } from '@icon-park/react';
import useBreakpoints from '@hooks/useBreakpoints';
import { Alert, Button, Divider, Select, Spin, Tooltip } from 'antd';
import { SendIcon } from '@components/icons/SendIcon';
import { SocketContext } from 'src/SocketComponent';
import { StopIcon } from '@components/icons/StopIcon';
import { useAppSelector } from '@hooks/useStore';
import { RetryIcon } from '@components/icons/RetryIcon';
import { LoadingOutlined } from '@ant-design/icons';
import strings from '@resources/localization';
import { CreatePostContext } from '../../../PostCreateProvider';
import useCommunity from '@hooks/useCommunity';
export const AlanEditor = (props) => {
    var _a, _b;
    const { maxLength, showCount, bordered = true, onChange, value, placeholder, minHeight, maxHeight } = props;
    const alanVoices = [
        { value: 'grateful', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.grateful },
        { value: 'uplifting', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.uplifting },
        { value: 'casual', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.casual },
        { value: 'inspirational', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.inspirational },
        { value: 'informative', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.informative },
        { value: 'optimistic', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.optimistic },
        { value: 'professional', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.professional },
    ];
    const context = useContext(CreatePostContext);
    const alanState = (_a = context === null || context === void 0 ? void 0 : context.alanState) === null || _a === void 0 ? void 0 : _a.get;
    const setAlanState = (_b = context === null || context === void 0 ? void 0 : context.alanState) === null || _b === void 0 ? void 0 : _b.set;
    const { cm_pk } = useCommunity();
    const { b_pk } = useParams();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const inputRef = useRef();
    const socket = useContext(SocketContext);
    const [numberCharacters, setNumberCharacters] = useState(0);
    const [fitState, setFitState] = useState(false);
    const [alanVoice, setAlanVoice] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [alanId, setAlanId] = useState(undefined);
    const [usage, setUsage] = useState(undefined);
    const language = useAppSelector((state) => state.ui.language);
    useEffect(() => {
        var _a;
        const lastMessage = socket.lastJsonMessage;
        if (((_a = lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.channel) === null || _a === void 0 ? void 0 : _a.id) == 'ai.chat.autocomplete') {
            const errorMessage = socket.lastJsonMessage;
            if (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.error) {
                setAlanState((prev) => {
                    return Object.assign(Object.assign({}, prev), { state: 'inactive' });
                });
                setError({ code: errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.error.error, message: errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.error.message });
            }
            else {
                const message = socket.lastJsonMessage;
                if (message.body.state == 'stop.done' || message.body.state == 'stop.length') {
                    setAlanState((prev) => {
                        return Object.assign(Object.assign({}, prev), { state: 'stop' });
                    });
                    setFitState(true);
                }
                else if (message.body.state == 'start') {
                    setAlanId(message.body.id);
                    setError(undefined);
                }
                else {
                    setUsage(Math.round((message.body.tokens.remaining * 100) / message.body.tokens.allotment));
                }
            }
        }
    }, [socket.lastJsonMessage]);
    useEffect(() => {
        if (usage <= 0) {
            setError({ code: 'application.feat.cycle.exceed' });
        }
    }, [usage]);
    useEffect(() => {
        socket.sendJsonMessage({
            channel: 'ai.chat.autocomplete',
            body: {
                action: 'prompt.allotment',
                communityPk: cm_pk,
            },
        });
    }, []);
    useEffect(() => {
        inputRef.current.addEventListener('paste', onPastePlainText);
    }, []);
    const onPastePlainText = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        if (maxLength) {
            const textToPaste = text.substring(0, maxLength - inputRef.current.innerText.length);
            document.execCommand('insertText', false, textToPaste);
            setNumberCharacters(inputRef.current.innerText.length);
        }
        else {
            document.execCommand('insertText', false, text);
        }
        return false;
    };
    const handleDown = (e) => {
        if (e.key === 'Enter') {
            document.execCommand('insertLineBreak', false, null);
            e.preventDefault();
        }
        if (numberCharacters >= maxLength && e.keyCode != 46 && e.keyCode != 8) {
            e.preventDefault();
            return;
        }
        if (alanState.state == 'running') {
            e.preventDefault();
            return;
        }
        if (e.keyCode === 9) {
            e.preventDefault();
            const doc = inputRef.current.ownerDocument.defaultView;
            const sel = doc.getSelection();
            const range = sel.getRangeAt(0);
            const tabNode = document.createTextNode('\u00a0\u00a0\u00a0\u00a0');
            range.insertNode(tabNode);
            range.setStartAfter(tabNode);
            range.setEndAfter(tabNode);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    };
    const handleInput = (e) => {
        const valueText = inputRef.current.innerText;
        setNumberCharacters(valueText.length);
        if (onChange)
            onChange(valueText);
    };
    const alanRequest = () => {
        if (alanState.state == 'running') {
            setAlanState({ state: 'stoping', times: alanState.times, voice: alanVoice });
            const message = {
                channel: 'ai.chat.autocomplete',
                body: {
                    action: 'prompt.stop',
                    id: alanId,
                },
            };
            socket.sendMessage(JSON.stringify(message));
        }
        else {
            setAlanState({ state: 'running', times: alanState.times + 1, voice: alanVoice });
            const message = {
                channel: 'ai.chat.autocomplete',
                body: Object.assign(Object.assign({ action: 'prompt.start', communityPk: cm_pk, prompt: value }, (alanVoice && { voice: alanVoice })), { locale: language }),
            };
            socket.sendMessage(JSON.stringify(message));
        }
    };
    const onFocus = () => {
        if (alanState.state == 'stop') {
            setFitState(false);
        }
    };
    const onBlur = () => {
        if (alanState.state == 'stop') {
            setFitState(true);
        }
    };
    const antIcon = _jsx(LoadingOutlined, { style: { fontSize: 16 }, spin: true });
    return (_jsxs("div", { style: {
            display: 'flex',
            flexDirection: 'column',
        }, children: [usage <= 20 && usage > 0 && (_jsx(Alert, { showIcon: true, closable: true, style: { marginBottom: 10 }, message: strings.formatString(strings.screens.boards.discussion.post.alan.warning, usage), type: "warning" })), _jsxs("div", { className: bordered ? style.editorWrapperBordered : style.editorWrapper, style: {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'text',
                }, children: [_jsx("p", { style: Object.assign(Object.assign({ whiteSpace: 'pre-wrap', wordBreak: 'break-word', display: 'inline-block', width: fitState ? '88%' : '100%', transition: '0.3s all' }, ((!fitState || !isMobile) && {
                            maxHeight: maxHeight ? maxHeight : 'unset',
                            overflow: 'auto',
                            minHeight: minHeight ? minHeight : 'unset',
                        })), (fitState &&
                            isMobile && {
                            minHeight: 16,
                            maxHeight: 22,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        })), onBlur: onBlur, onFocus: onFocus, className: ClassNames(style.editor, 'scrollStyle'), id: "editor", contentEditable: true, onKeyDown: handleDown, onInput: handleInput, ref: inputRef, placeholder: placeholder }), _jsxs("div", { children: [(alanState.state == 'running' || alanState.state == 'stoping') && (_jsx(Spin, { indicator: antIcon, style: { position: 'absolute', right: 12, bottom: 66, color: 'var( --neutral-7)' } })), alanState.state == 'stop' && (_jsx(CheckOne, { style: { position: 'absolute', right: 12, bottom: 66 }, theme: "filled", size: "16", fill: "var(--polar-green-7)", strokeLinejoin: "bevel" })), _jsx(Divider, { style: { marginTop: 8, marginBottom: 8 } }), _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, children: [_jsx(Select, { allowClear: true, onChange: (value) => {
                                            setAlanVoice(value);
                                        }, style: { overflow: 'hidden' }, dropdownStyle: { zIndex: 2000 }, options: alanVoices, placeholder: strings.screens.boards.discussion.post.alan.fieldVoice.placeholder }), _jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [showCount && maxLength && (_jsx("p", { style: { marginBottom: 0, color: 'var(--neutral-6)', fontSize: 14, lineHeight: '22px', marginRight: 16 }, children: `${numberCharacters}/${maxLength}` })), _jsx(Tooltip, { title: alanState.state == 'running' || alanState.state == 'stoping'
                                                    ? 'Detener'
                                                    : alanState.state == 'stop'
                                                        ? 'Actualizar'
                                                        : 'Generar texto', children: _jsx(Button, { loading: alanState.state == 'stoping', onClick: alanRequest, className: "hover-svg", style: { display: 'flex', justifyContent: 'center', alignItems: 'center' }, icon: alanState.state == 'running' || alanState.state == 'stoping' ? (_jsx(StopIcon, { style: { width: '16px', height: '16px' } })) : alanState.state == 'stop' ? (_jsx(RetryIcon, { style: { width: '14px', height: '14px' } })) : (_jsx(SendIcon, { style: { width: '14px', height: '14px' } })), shape: "circle" }) })] })] })] })] }), error && (_jsx(Alert, { showIcon: true, style: { marginTop: 10 }, message: strings.screens.boards.discussion.post.alan.errors[error.code] || error.message, type: "error" }))] }));
};
