import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Input, List } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import { chatMessageReport, chatReport, postReport } from '@state/actions';
import { useRequestState } from '@api/requests/AsyncRequests';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import useCommunity from '@hooks/useCommunity';
import { InformationModalBody } from '../information_modal/InformationModalBody';
import { FooterModal } from '../components/FooterModal';
import useBreakpoints from '@hooks/useBreakpoints';
import { ArrowRightIcon } from '@components/icons/ArrowRightIcon';
export const ReportModal = (props) => {
    const { open, reference, onCancel, kind } = props;
    const [step, setStep] = useState('selection');
    const [choice, setChoice] = useState();
    const { cm_pk } = useCommunity();
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const [state, setState] = useRequestState(undefined, false);
    const handleSelect = (item) => {
        setStep('confirm');
        setChoice(item);
    };
    const thenAction = () => {
        setStep('finish');
    };
    const goBack = () => {
        setStep('selection');
        setChoice(undefined);
    };
    const submit = (values) => {
        if (props.kind == 'post' || props.kind == 'rate' || props.kind == 'comment') {
            const referencePost = reference;
            dispatch(postReport({
                bodyParams: {
                    communityPk: cm_pk,
                    boardPk: referencePost.b_pk,
                    postPk: referencePost.p_pk,
                    reason: choice,
                    title: values.title,
                    explanation: values.explanation,
                    notifyEmail: true,
                },
                options: {
                    redux: {
                        stateHandler: setState,
                    },
                    then: {
                        action: thenAction,
                    },
                },
            }));
        }
        if (props.kind == 'room') {
            const referenceRoom = reference;
            dispatch(chatReport({
                bodyParams: {
                    communityPk: cm_pk,
                    roomPk: referenceRoom.r_pk,
                    reason: choice,
                    title: values.title,
                    explanation: values.explanation,
                    notifyEmail: true,
                },
                options: {
                    redux: {
                        stateHandler: setState,
                    },
                    then: {
                        action: thenAction,
                    },
                },
            }));
        }
        if (props.kind == 'message') {
            const referenceMessage = reference;
            dispatch(chatMessageReport({
                bodyParams: {
                    communityPk: cm_pk,
                    roomPk: referenceMessage.r_pk,
                    messagePk: referenceMessage.m_pk,
                    reason: choice,
                    title: values.title,
                    explanation: values.explanation,
                    notifyEmail: true,
                },
                options: {
                    redux: {
                        stateHandler: setState,
                    },
                    then: {
                        action: thenAction,
                    },
                },
            }));
        }
    };
    useEffect(() => {
        setStep('selection');
        setChoice(undefined);
    }, [open]);
    const data = Object.entries(strings.reports.kinds);
    return (_jsxs(TuringoModal, { width: 572, closable: true, title: null, open: open, onCancel: onCancel, footer: null, bodyStyle: { textAlign: 'start', padding: step == 'selection' ? '4px 0px 16px 0px' : 0 }, children: [step == 'selection' && (_jsxs("div", { children: [_jsxs("div", { style: { padding: 24 }, children: [_jsx(ExclamationCircleOutlined, { style: { color: '#FAAD14', fontSize: 56, marginBottom: 20 } }), _jsx(Paragraph, { style: { marginBottom: 16 }, strong: true, children: strings.reports.initial.title }), _jsx(Paragraph, { children: strings.reports.initial.description[kind] })] }), _jsx(List, { itemLayout: "horizontal", dataSource: data, renderItem: (item) => (_jsx(List.Item, { className: 'turingo-list-item', onClick: () => handleSelect(item[0]), actions: [
                                _jsx("span", { style: { marginRight: 16 }, children: _jsx(ArrowRightIcon, {}) }, "list-loadmore-edit"),
                            ], children: _jsx("span", { style: { paddingLeft: 24, paddingRight: 24 }, className: 'turingo-list-item-text', children: item[1] }) })) })] })), step == 'confirm' && (_jsx("div", { children: _jsxs("div", { style: { padding: 24 }, children: [_jsx(ExclamationCircleOutlined, { style: { color: '#FAAD14', fontSize: 56, marginBottom: 20 } }), _jsx(Paragraph, { style: { marginBottom: 16 }, strong: true, children: strings.reports.initial.title }), _jsxs(Paragraph, { style: { marginBottom: 16 }, children: [strings.reports.selected.title, " ", _jsx("span", { style: { fontWeight: 500 }, children: strings.reports.kinds[choice] + '.' })] }), _jsx(Paragraph, { style: { marginBottom: 24 }, children: strings.reports.selected.description }), choice != 'other' && _jsx(Paragraph, { style: { marginBottom: 56 }, children: strings.reports.selected.continue }), _jsx(Form, { onFinish: submit, form: form, layout: 'vertical', children: choice == 'other' && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: ['title'], rules: [
                                            { required: true, message: strings.forms.report },
                                            { min: 5, message: strings.forms.report },
                                        ], label: strings.reports.selected.other.label, children: _jsx(Input, { placeholder: strings.reports.selected.other.prompt }) }), _jsx(Form.Item, { style: { marginBottom: 56 }, name: ['explanation'], label: strings.reports.selected.other_details.label, children: _jsx(Input.TextArea, { autoSize: { minRows: 3, maxRows: 3 }, showCount: true, maxLength: 500 }) })] })) }), _jsx(FooterModal, { primary: {
                                action: form.submit,
                                customText: strings.general.continue,
                            }, secondary: {
                                action: onCancel,
                                customText: strings.general.cancel,
                            }, back: {
                                action: goBack,
                                kind: 'back',
                            } })] }) })), step == 'finish' && (_jsx("div", { children: _jsxs("div", { style: { padding: isMobile ? '24px 16px' : 24 }, children: [_jsx(InformationModalBody, { kind: 'success', title: strings.reports.finish.success.title, description: strings.reports.finish.success.description }), _jsx(FooterModal, { primary: {
                                action: onCancel,
                                customText: strings.general.finish,
                            } }, 'footer_modal')] }) }))] }));
};
