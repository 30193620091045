var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { API } from '@api/API';
import { generateRandomString } from '@hooks/oauth/useOAuth';
import useScrollPosition from '@hooks/useScrollPosition';
import { getLastMention, insertMention } from '@util/string/Functions';
import ClassNames from '@util/style/ClassNames';
import { isEqual, differenceWith, isEmpty } from 'lodash';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useWindowSize } from 'react-use';
import { Autocomplete } from './AutoComplete';
import style from './TuringoEditor.scss';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import Picker from '@emoji-mart/react';
import { SlightlySmilingFace } from '@icon-park/react';
import useBreakpoints from '@hooks/useBreakpoints';
import { Alert, Button, Form, Tooltip } from 'antd';
import { AlanIcon } from '@components/icons/AlanIcon';
import { useAppSelector } from '@hooks/useStore';
import { CreatePostContext } from '@components/content/posts/post_modal/PostCreateProvider';
import { LoadingOutlined } from '@ant-design/icons';
import { CrossPostingContext } from '@components/content/posts/post_modal/screens/creation/CreationScreen';
import { createPortal } from 'react-dom';
import useCommunity from '@hooks/useCommunity';
const linkify = linkifyIt().tlds(tlds);
const isArrayEqual = function (x, y) {
    return isEmpty(differenceWith(x, y, isEqual));
};
const getTextSelection = function (editor) {
    const selection = window.getSelection();
    if (selection != null && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        return {
            start: getTextLength(editor, range.startContainer, range.startOffset),
            end: getTextLength(editor, range.endContainer, range.endOffset),
        };
    }
    else
        return null;
};
const getTextLength = function (parent, node, offset) {
    let textLength = 0;
    if (!node)
        return textLength;
    if (node.nodeName == '#text')
        textLength += offset;
    else
        for (let i = 0; i < offset; i++)
            textLength += getNodeTextLength(node.childNodes[i]);
    if (node != parent)
        textLength += getTextLength(parent, node.parentNode, getNodeOffset(node));
    return textLength;
};
const getNodeTextLength = function (node) {
    let textLength = 0;
    if (node.nodeName == 'BR')
        textLength = 1;
    else if (node.nodeName == '#text')
        textLength = node.nodeValue.length;
    else if (node.childNodes != null)
        for (let i = 0; i < node.childNodes.length; i++)
            textLength += getNodeTextLength(node.childNodes[i]);
    return textLength;
};
const getNodeOffset = function (node) {
    return node == null ? -1 : 1 + getNodeOffset(node.previousSibling);
};
function getCaretCoordinates() {
    let x = 0, y = 0;
    const isSupported = typeof window.getSelection !== 'undefined';
    if (isSupported) {
        const selection = window.getSelection();
        if (selection.rangeCount !== 0) {
            const range = selection.getRangeAt(0).cloneRange();
            range.collapse(true);
            const rect = range.getClientRects()[0];
            if (rect) {
                x = rect.left;
                y = rect.top;
            }
        }
    }
    return { x, y };
}
function placeCaretAtEnd(el) {
    if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }
}
function setCursorPosition(parent, range, stat) {
    if (stat.done)
        return range;
    if (parent.nodeType === Node.TEXT_NODE) {
        const textNode = parent;
        if (textNode.textContent.length >= stat.pos) {
            range.setStart(textNode, stat.pos);
            stat.done = true;
        }
        else {
            stat.pos -= textNode.textContent.length;
        }
    }
    else if (parent.nodeType === Node.ELEMENT_NODE) {
        const elementNode = parent;
        for (let i = 0; i < elementNode.childNodes.length && !stat.done; i++) {
            const currentNode = elementNode.childNodes[i];
            setCursorPosition(currentNode, range, stat);
        }
    }
    return range;
}
export const TuringoEditor = forwardRef(function editor(props, ref) {
    var _a, _b;
    const { kind = 'comment', maxLength, showCount, boardSelected, bordered = true, onChange, value, onMentionsChange, initialMentions, hasMentions = true, placeholder, minHeight, maxHeight, showEmojis, onError, onFocus, } = props;
    const context = useContext(CreatePostContext);
    const contextCrossPosting = useContext(CrossPostingContext);
    const { cm_pk } = useCommunity();
    const { b_pk } = useParams();
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const isAdmin = (_b = (_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.includes('edit');
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const inputRef = useRef();
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const windowSize = useWindowSize();
    const [mentions, setMentions] = useState();
    const [leftOffset, setLeftOffset] = useState(0);
    const [editorWidth, setEditorWidth] = useState(0);
    const [length, setLength] = useState(value ? value.length : 0);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const emojiButtonRef = useRef();
    const [emojiOffsetTop, setEmojiOffsetTop] = useState(0);
    const [emojiOffsetLeft, setEmojiOffsetLeft] = useState(0);
    const [numberCharacters, setNumberCharacters] = useState(0);
    const [emojiData, setEmojiData] = useState();
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastPosition, setLastPosition] = useState({ start: 0, end: 0 });
    const [textPosition, setTextPosition] = useState({ start: 0, end: 0 });
    const [lastCaret, setLastCaret] = useState({ start: 0, end: 0 });
    const [topOffset, setTopOffset] = useState(0);
    const isActive = Form.useWatch(['form', contextCrossPosting === null || contextCrossPosting === void 0 ? void 0 : contextCrossPosting.consumer, 'active'], context === null || context === void 0 ? void 0 : context.form);
    const scrollPosition = useScrollPosition();
    const [error, setError] = useState(undefined);
    const [timer, setTimer] = useState();
    const language = useAppSelector((state) => state.ui.language);
    const [links, setLinks] = useState(value && linkify.match(value)
        ? linkify.match(value).map((item) => {
            return {
                kind: 'link',
                publicKey: item.url,
                text: item.text,
                length: item.lastIndex - item.index,
                offset: item.index,
            };
        })
        : []);
    useEffect(() => {
        var _a, _b;
        setEmojiOffsetTop(((_a = emojiButtonRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().top) + window.scrollY - 435 - 22);
        setEmojiOffsetLeft(((_b = emojiButtonRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().left) + window.scrollX);
    });
    useEffect(() => {
        if (value) {
            changeHighlights(initialMentions === null || initialMentions === void 0 ? void 0 : initialMentions.map((mention) => {
                return {
                    id: mention.id ? mention.id : generateRandomString(7),
                    publicKey: mention.publicKey,
                    kind: 'mention',
                    text: mention.text ? mention.text : value.substring(mention.offset, mention.offset + mention.length),
                    length: mention.length,
                    offset: mention.offset,
                };
            }).reduce((obj, item) => Object.assign(obj, { [item.id]: Object.assign({}, item) }), {}), links, value, value.length);
        }
    }, []);
    useEffect(() => {
        if (inputRef && inputRef.current) {
            setLeftOffset(inputRef.current.getBoundingClientRect().left);
            setTopOffset(getCaretCoordinates().y + scrollPosition);
            setEditorWidth(inputRef.current.offsetWidth);
        }
    }, [windowSize]);
    useEffect(() => {
        if (value) {
            handleLinks(value);
            setLength(value.length);
        }
    }, [value]);
    useEffect(() => {
        if (isActive) {
            if (numberCharacters > maxLength) {
                if (!error) {
                    if (onError)
                        onError('La publicación en X debe tener máximo 280 caracteres');
                    setError('La publicación en X debe tener máximo 280 caracteres');
                }
            }
            else {
                if (error) {
                    if (onError)
                        onError(undefined);
                    setError(undefined);
                }
            }
        }
        else {
            if (error) {
                if (onError)
                    onError(undefined);
                setError(undefined);
            }
        }
    }, [numberCharacters, isActive]);
    useEffect(() => {
        void fetchEmojiData();
        inputRef.current.addEventListener('paste', onPastePlainText);
    }, []);
    const fetchEmojiData = () => __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');
        setEmojiData(yield response.json());
    });
    useImperativeHandle(ref, () => ({
        getMentions() {
            return mentions;
        },
        getValue() {
            return inputRef.current.innerText;
        },
        focus() {
            inputRef.current.focus();
            placeCaretAtEnd(inputRef.current);
        },
        replyMention(target) {
            const id = generateRandomString(7);
            inputRef.current.innerHTML = `<span id='${'mention'}' data-id='${id}' >${target.name}</span>`;
            const newMention = {
                id: id,
                publicKey: target.publicKey,
                kind: 'mention',
                text: target.name,
                length: target.name.length,
                offset: 0,
            };
            changeHighlights({
                [id]: newMention,
            }, links, inputRef.current.innerText, target.name.length, newMention);
            setLength(target.name.length + 1);
        },
        resetEditor() {
            if (onMentionsChange) {
                onMentionsChange([]);
            }
            setNumberCharacters(0);
            inputRef.current.innerText = '';
            placeCaretAtEnd(inputRef.current);
        },
    }));
    const onPastePlainText = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        if (maxLength) {
            const textToPaste = text.substring(0, maxLength - inputRef.current.innerText.length);
            document.execCommand('insertText', false, textToPaste);
            setNumberCharacters(inputRef.current.innerText.length);
        }
        else {
            document.execCommand('insertText', false, text);
        }
        return false;
    };
    const changeHighlights = (data, links, text, lenghtDifference, lastReplacement, lastPosition, lastKey) => {
        if (lastKey != 'insertCompositionText') {
            const mentionsArray = Object.values(data || {});
            setMentions(data || {});
            if (onMentionsChange)
                onMentionsChange(mentionsArray);
            inputRef.current.innerHTML = insertMention(text, [...mentionsArray, ...links], lastReplacement);
            if (lastPosition != 0) {
                const sel = window.getSelection();
                sel.removeAllRanges();
                let position = lastPosition ? lastPosition : lastCaret.start;
                position = lastReplacement ? position + 1 : position;
                const range = setCursorPosition(inputRef.current, document.createRange(), { pos: position + lenghtDifference, done: false });
                range.collapse(true);
                sel.addRange(range);
            }
        }
    };
    const handleMentionChanges = (currentLength, newLength, selection, isMentionInsert, isDeletingForward) => {
        let data = mentions;
        if (currentLength != newLength)
            setLength(newLength);
        const isDeleting = currentLength > newLength;
        const diff = newLength - currentLength;
        if (data) {
            Object.keys(data).forEach((key) => {
                const mentionStart = data[key].offset;
                const mentionEnd = data[key].offset + data[key].length;
                if (selection.start == undefined || selection.end == selection.start) {
                    if (selection.end <= mentionStart && !isDeletingForward) {
                        data = Object.assign(Object.assign({}, data), { [key]: Object.assign(Object.assign({}, data[key]), { offset: mentionStart + diff, length: data[key].length }) });
                    }
                    else if (selection.end == mentionEnd && isDeleting) {
                        const newData = Object.assign({}, data);
                        delete newData[key];
                        data = newData;
                    }
                    else if (selection.end == mentionEnd && !isDeleting) {
                        return;
                    }
                    else if (selection.end > mentionStart && selection.end <= mentionEnd) {
                        const newData = Object.assign({}, data);
                        delete newData[key];
                        data = newData;
                    }
                    else if (selection.end == mentionStart && isDeletingForward) {
                        const newData = Object.assign({}, data);
                        delete newData[key];
                        data = newData;
                    }
                }
                else {
                    let start = 0;
                    let end = 0;
                    if (selection.end > selection.start) {
                        start = selection.start;
                        end = selection.end;
                    }
                    else {
                        start = selection.end;
                        end = selection.start;
                    }
                    if (start < mentionStart && end < mentionStart) {
                        data = Object.assign(Object.assign({}, data), { [key]: Object.assign(Object.assign({}, data[key]), { offset: mentionStart + diff, length: data[key].length }) });
                    }
                    else if ((start >= mentionStart && start <= mentionEnd && isMentionInsert) ||
                        (start <= mentionStart && end >= mentionStart && isMentionInsert)) {
                        data = Object.assign(Object.assign({}, data), { [key]: Object.assign(Object.assign({}, data[key]), { offset: mentionStart + diff, length: data[key].length }) });
                    }
                    else if ((start > mentionStart && start <= mentionEnd) || (start <= mentionStart && end >= mentionStart)) {
                        const newData = Object.assign({}, data);
                        delete newData[key];
                        data = newData;
                    }
                }
            });
        }
        return data;
    };
    const handleUp = (e) => {
        if (e.key === 'Dead') {
            e.preventDefault();
            return;
        }
    };
    const handlePress = (e) => {
        if (e.key === 'Dead') {
            e.preventDefault();
            return;
        }
    };
    const handleDown = (e) => {
        if (e.key === 'Enter') {
            document.execCommand('insertLineBreak', false, null);
            e.preventDefault();
        }
        if (e.key === 'Dead') {
            e.preventDefault();
            return;
        }
        if (numberCharacters >= maxLength && e.keyCode != 46 && e.keyCode != 8) {
            e.preventDefault();
            return;
        }
        if (props.submitOnEnter && e.keyCode === 13 && !e.shiftKey) {
            props.submitOnEnter();
            if (onMentionsChange) {
                onMentionsChange([]);
            }
            inputRef.current.innerText = '';
            placeCaretAtEnd(inputRef.current);
            e.preventDefault();
            return;
        }
        const sel = window.getSelection();
        if (sel.toString() != '') {
            setLastPosition(getTextSelection(inputRef.current));
        }
        else {
            setLastPosition({ end: getTextSelection(inputRef.current).end });
        }
    };
    const fetchMentions = (wordText) => {
        void API.userMentions({
            urlParams: { cm_pk },
            searchParams: Object.assign(Object.assign(Object.assign({}, (b_pk && { boardPk: b_pk })), (boardSelected && { boardPk: boardSelected })), { term: wordText.slice(1) }),
        }).then((response) => {
            if (response.data.length > 0) {
                const data = response.data;
                setLoading(false);
                setSuggestions(data);
            }
            else {
                setLoading(false);
                setSuggestions([]);
                setShowAutoComplete(false);
            }
        });
    };
    const handleLinks = (text) => {
        const links = linkify.match(text);
        let formattedLinks = [];
        if (links) {
            formattedLinks = links.map((item) => {
                return {
                    kind: 'link',
                    publicKey: item.url,
                    text: item.text,
                    length: item.lastIndex - item.index,
                    offset: item.index,
                };
            });
        }
        if (!links) {
            setLinks([]);
            return [];
        }
        else {
            if (!isArrayEqual(formattedLinks, links)) {
                setLinks(formattedLinks);
                return formattedLinks;
            }
            else {
                false;
            }
        }
    };
    const handleInput = (e) => {
        setLastCaret(getTextSelection(inputRef.current));
        setEditorWidth(inputRef.current.offsetWidth);
        const selection = getTextSelection(inputRef.current);
        const valueText = inputRef.current.innerText;
        setNumberCharacters(valueText.length);
        const new_links = handleLinks(valueText);
        const mentionsAfterInput = handleMentionChanges(length, valueText.length, lastPosition, false, e.nativeEvent.inputType == 'deleteContentForward');
        changeHighlights(mentionsAfterInput, new_links, inputRef.current.innerText, 0, undefined, selection.end, e.nativeEvent.inputType);
        const { word: wordText, valid } = getLastMention(valueText, valueText.substring(0, selection.end).lastIndexOf('@'), selection.end, true);
        const shouldOpenAutoComplete = hasMentions
            ? /^@([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]{2,15}(\s?))([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]{1,15}(\s?)){1,2}$/.test(wordText)
            : false;
        if (shouldOpenAutoComplete && valid) {
            if (timer) {
                clearTimeout(timer);
            }
            setLoading(true);
            const timerRef = setTimeout(() => fetchMentions(wordText), 1100);
            setTimer(timerRef);
            const selection = getTextSelection(inputRef.current);
            setTopOffset(getCaretCoordinates().y + scrollPosition);
            setTextPosition(selection);
        }
        setShowAutoComplete(shouldOpenAutoComplete && valid);
        if (onChange)
            onChange(valueText);
    };
    const handleSelection = (userHandle) => {
        const text = inputRef.current.innerText;
        const { word, range } = getLastMention(text, text.substring(0, textPosition.end + 1).lastIndexOf('@'), textPosition.end + 1, true);
        if (range) {
            const [offset] = range;
            const mentionId = generateRandomString(7);
            const prefix = value.substring(0, offset);
            const suffix = value.substring(offset + word.length + 1);
            inputRef.current.innerText = prefix + userHandle.name + suffix;
            setLastCaret({ start: lastCaret.start - word.length + userHandle.name.length, end: lastCaret.end - word.length + userHandle.name.length });
            const mentionsAfterInsert = handleMentionChanges(inputRef.current.innerText.length - userHandle.name.length + word.length, inputRef.current.innerText.length, {
                start: textPosition.start - word.length,
                end: textPosition.start - word.length + userHandle.name.length,
            }, true);
            const newMention = {
                id: mentionId,
                kind: 'mention',
                publicKey: userHandle.publicKey,
                offset: offset,
                length: userHandle.name.length,
                text: userHandle.name,
            };
            changeHighlights(Object.assign(Object.assign({}, mentionsAfterInsert), { [mentionId]: newMention }), links, inputRef.current.innerText, 0, newMention, lastCaret.start - word.length + userHandle.name.length);
            setShowAutoComplete(false);
            if (onChange)
                onChange(inputRef.current.innerText);
        }
    };
    const onMouse = () => {
        setShowAutoComplete(false);
        setLastCaret(getTextSelection(inputRef.current));
    };
    const onEmojiSelect = (e) => {
        const prefix = inputRef.current.innerText.substring(0, lastCaret.start);
        const suffix = inputRef.current.innerText.substring(lastCaret.end);
        const newText = prefix + e.native + suffix;
        inputRef.current.innerText = newText;
        setNumberCharacters(newText.length);
        setLastCaret({ start: lastCaret.end + e.native.length, end: lastCaret.end + e.native.length });
        inputRef.current.focus();
        const sel = window.getSelection();
        sel.removeAllRanges();
        const range = setCursorPosition(inputRef.current, document.createRange(), { pos: lastCaret.end + e.native.length, done: false });
        range.collapse(true);
        sel.addRange(range);
        setShowEmojiPicker(false);
        if (onChange)
            onChange(newText);
    };
    const antIcon = _jsx(LoadingOutlined, { style: { fontSize: 16 }, spin: true });
    return (_jsxs("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }, children: [_jsxs("div", { className: bordered ? style.editorWrapperBordered : style.editorWrapper, style: {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: kind != 'comment' ? 'column' : 'row',
                    cursor: 'text',
                }, children: [showEmojiPicker &&
                        createPortal(_jsx("div", { style: {
                                top: emojiOffsetTop + 12,
                                left: emojiOffsetLeft,
                                position: 'absolute',
                                width: editorWidth,
                                zIndex: 1002,
                            }, children: _jsx(Picker, { previewPosition: 'none', skinTonePosition: 'search', emojiButtonSize: 32, emojiSize: 24, locale: language, data: emojiData, onEmojiSelect: onEmojiSelect, onClickOutside: () => setShowEmojiPicker(false) }) }), document.body), showEmojis && !isMobile && (_jsx("div", { ref: emojiButtonRef, style: { display: 'flex' }, children: _jsx(SlightlySmilingFace, { className: style.emoji, style: { marginRight: 8, display: 'flex' }, theme: "outline", size: "22", onClick: () => setShowEmojiPicker(!showEmojiPicker) }) })), _jsx("p", { style: Object.assign({ whiteSpace: 'pre-wrap', wordBreak: 'break-word', display: 'inline-block', width: '100%' }, (!isMobile && {
                            maxHeight: maxHeight ? maxHeight : 'unset',
                            overflow: 'auto',
                            minHeight: minHeight ? minHeight : 'unset',
                        })), onFocus: onFocus, className: ClassNames(style.editor, 'scrollStyle'), id: "editor", contentEditable: true, onKeyUp: handleUp, onInput: handleInput, onKeyPress: handlePress, onKeyDown: handleDown, ref: inputRef, onMouseUp: onMouse, placeholder: placeholder }), showAutoComplete && (_jsx(Autocomplete, { loading: loading, items: suggestions, width: editorWidth, handleSelection: handleSelection, top: topOffset + 22, left: leftOffset }))] }), showCount && maxLength && (_jsx("p", { style: { alignSelf: 'end', marginBottom: 0, color: 'var(--neutral-6)', fontSize: 14, lineHeight: '22px' }, children: `${numberCharacters}/${maxLength}` })), (context === null || context === void 0 ? void 0 : context.setScreen) && kind == 'post' && (_jsxs("div", { style: { display: 'flex', justifyContent: isAdmin ? (isMobile ? 'end' : 'space-between') : 'start', marginTop: 16 }, children: [!isMobile && (_jsx(Tooltip, { title: "Emoji", children: _jsx(Button, { icon: _jsx(SlightlySmilingFace, { style: { display: 'flex', justifyContent: 'center', alignItems: 'center' }, theme: "outline", size: "14" }), ref: emojiButtonRef, onClick: () => setShowEmojiPicker(!showEmojiPicker), shape: "circle", type: "default" }) })), isAdmin && (_jsx(Tooltip, { title: "Generador de texto", children: _jsx(Button, { style: {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }, className: "hover-svg", onClick: () => context === null || context === void 0 ? void 0 : context.setScreen('alan'), shape: "circle", type: "default", icon: _jsx(AlanIcon, { style: { width: '14px', height: '14px' } }) }) }))] })), error && _jsx(Alert, { style: { marginTop: 10 }, message: error, type: "error" })] }));
});
