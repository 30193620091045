import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Meta from 'react-meta-tags';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useHistory, useLocation } from 'react-router';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Typography from 'antd/lib/typography';
import Affix from 'antd/lib/affix';
import Button from 'antd/lib/button';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ImageLogoIsoTuringo from '@bundle/assets/branding/isotype.svg';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { collapseSidebar, tableColor } from './state/reducers/UserInterface/UserInterfaceReducer';
import { isEntityLanding } from '@util/router/ReverseRouter';
import { Router } from './Router';
import SVG from '@components/assets/SVG';
import { Header } from '@components/navegation/header/Header';
import { SpinLoader } from './components/content/spin_loader/SpinLoader';
import { FooterBar } from './components/navegation/footer/Footer';
import { FooterPurchase } from './components/navegation/footer/FooterPurchase';
import { EntityAnchor } from '@components/content/entity/entity_anchor/EntityAnchor';
import { SystemError } from '@components/modals/SystemError';
import { AlertActions } from '@components/alerts/alert_actions/AlertActions';
import ConfigProvider from 'antd/lib/config-provider';
import { turingoRoutes } from '@components/routing/auth/AuthRouting';
import { SocketComponent } from './SocketComponent';
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap';
import { CommunitiesLayout } from './CommunitiesLayout';
import { GlobalModals } from './GlobalModals';
import { setErrorAction, userSelf } from '@state/actions';
import { Layout } from 'antd';
import { getCurrentCommunity } from '@util/community/getCurrent';
export const hideSidebarRoutes = (pathname, validation) => {
    var _a, _b;
    const cm_pk = getCurrentCommunity();
    return (pathname.startsWith('/validation') ||
        pathname.startsWith('/cert') ||
        pathname.startsWith('/billing') ||
        pathname.startsWith('/billing/cart') ||
        pathname.startsWith('/redeem') ||
        pathname.startsWith('/saas') ||
        pathname.startsWith('/auth/validate') ||
        (cm_pk && ((_a = pathname.split('/')[1]) === null || _a === void 0 ? void 0 : _a.startsWith('onboarding'))) ||
        (!cm_pk && ((_b = pathname.split('/')[2]) === null || _b === void 0 ? void 0 : _b.startsWith('onboarding'))) ||
        validation == 'blocked');
};
const Application = () => {
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const language = useAppSelector((state) => state.ui.language);
    const error_code = useAppSelector((state) => state.error.code);
    const isBlocked = useAppSelector((state) => state.auth.authValidation == 'blocked');
    const location = useLocation();
    const validation = useAppSelector((state) => state.auth.authValidation);
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const collapsed = useAppSelector((state) => state.ui.collapsed);
    const loader = useAppSelector((state) => state.modal.loader.status);
    const theme = useAppSelector((state) => state.ui.theme);
    const color = useAppSelector((state) => state.ui.color);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const isPurchase = location.pathname.startsWith('/billing/cart');
    const noMarginTop = location.pathname == '/community' ||
        location.pathname == '/' ||
        location.pathname == '/saas' ||
        location.pathname == '/saas/form' ||
        location.pathname == '/turingo/courses' ||
        location.pathname == '/turingo/talks' ||
        location.pathname == '/turingo/stories' ||
        location.pathname.startsWith('/billing');
    const isClassroomLanding = isEntityLanding('courses');
    const isForumLanding = isEntityLanding('talks');
    const isCommunity = !turingoRoutes.includes(!getCurrentCommunity() ? location.pathname.split('/')[1] : getCurrentCommunity());
    const isAuthPage = location.pathname.includes('/signup') || location.pathname.includes('/login');
    const isB2BAuthPage = (location.pathname.includes('/signup') && isCommunity) ||
        (location.pathname.includes('/login') && isCommunity) ||
        (location.pathname.includes('/join') && isCommunity);
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const community = useAppSelector((state) => state.community.items[currentCommunity]);
    useEffect(() => {
        var _a;
        if (community === null || community === void 0 ? void 0 : community.item) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = (_a = community.item) === null || _a === void 0 ? void 0 : _a.logoUrl;
        }
    }, [community]);
    useEffect(() => {
        if (isLogged) {
            void dispatch(userSelf({}));
        }
    }, []);
    const isCommunityPage = (location.pathname.startsWith('/turingo') || location.pathname.startsWith('/user')) && !noMarginTop;
    const isCallback = location.pathname == '/google' || location.pathname == '/facebook' || location.pathname == '/linkedin';
    useEffect(() => {
        if (!isCommunity && !isLogged) {
            setColor('turingo_color');
        }
    }, [location]);
    useEffect(() => {
        return;
    }, [language]);
    useEffect(() => {
        if (validation == 'blocked' &&
            !location.pathname.startsWith('/blocked') &&
            !location.pathname.startsWith('/help') &&
            !location.pathname.startsWith('/aboutUs') &&
            !location.pathname.startsWith('/terms') &&
            !location.pathname.startsWith('/privacy') &&
            !location.pathname.startsWith('/auth/validate')) {
            history.push('/blocked');
        }
        return;
    }, [validation, location]);
    const setColor = (color) => {
        document.body.classList.remove('red', 'blue', 'cyan', 'green', 'turingo_color', 'magenta', 'volcano', 'orange');
        document.body.classList.add(color);
    };
    useEffect(() => {
        ConfigProvider.config({
            theme: {
                primaryColor: tableColor[color],
            },
        });
        if (theme === 'light') {
            document.querySelector("meta[name='theme-color']").setAttribute('content', '#ffffff');
            document.body.classList.remove('dark');
            document.body.classList.add('light');
            setColor(color);
            document.documentElement.style.setProperty('color-scheme', 'light');
            document.documentElement.style.setProperty('background', '#fff');
        }
        else {
            document.querySelector("meta[name='theme-color']").setAttribute('content', '#262626');
            document.body.classList.remove('light');
            document.body.classList.add('dark');
            setColor(color);
            document.documentElement.style.setProperty('color-scheme', 'dark');
            document.documentElement.style.setProperty('background', '#181A1C');
        }
        setLoading(false);
    }, [theme, color]);
    useEffect(() => {
        if (breakpoints.isMobile) {
            document.body.classList.add('mobile');
        }
        else {
            document.body.classList.remove('mobile');
        }
    }, [breakpoints.isMobile]);
    useEffect(() => {
        if (!collapsed && breakpoints.lg) {
            dispatch(collapseSidebar(true));
        }
    }, [breakpoints.lg]);
    useEffect(() => {
        if (error_code == 'access.notallowed') {
            if (!isLogged) {
                if (!location.pathname.includes('/login')) {
                    const community = !getCurrentCommunity() ? location.pathname.split('/')[1] : getCurrentCommunity();
                    dispatch(setErrorAction(undefined));
                    history.push(Router.Path.community_login({ cm_pk: community }), { to: location.pathname });
                }
            }
        }
    }, [error_code, isLogged]);
    if (isCallback) {
        return (_jsx(Row, { justify: "center", style: { height: '100vh', alignContent: 'center', backgroundColor: theme == 'dark' ? '#141414' : '#FAFAFA' }, children: _jsx(Col, { children: _jsx(Router.Component, {}) }) }));
    }
    if (loading) {
        return (_jsx(Row, { justify: "center", style: { height: '100vh', alignContent: 'center', backgroundColor: theme == 'dark' ? '#141414' : '#FAFAFA' }, children: _jsx(Col, { children: _jsx(SVG, { style: { width: '50px', height: '50px' }, content: ImageLogoIsoTuringo }) }) }));
    }
    return (_jsxs(SpinLoader, { loading: loader, children: [_jsx(Meta, { children: _jsx("meta", { property: "og:locale", content: strings.getLanguage() }) }), _jsx(ConditionalWrap, { condition: isLogged, wrap: (children) => _jsx(SocketComponent, { children: children }), children: _jsxs(Layout, { className: "layout", style: { minHeight: '100vh' }, children: [_jsx(Header, { border: !(isMobile && (isClassroomLanding || isForumLanding || location.pathname.startsWith('/billing'))), error: error_code }), _jsx(GlobalModals, {}), (!error_code || location.pathname.includes('/login')) && (_jsxs(_Fragment, { children: [isMobile && (isClassroomLanding || isForumLanding) && _jsx(EntityAnchor, {}), _jsx("div", { style: {
                                        width: '100%',
                                        alignSelf: 'center',
                                        flexGrow: 1,
                                    }, children: _jsx(Layout, { style: { width: '100%' }, children: _jsx(CommunitiesLayout, {}) }) })] })), error_code && !location.pathname.includes('/login') && _jsx(SystemError, { error: error_code }), isPurchase && _jsx(FooterPurchase, {}), false && isCommunityPage && !isAuthPage && !isLogged && (_jsx(Affix, { offsetBottom: 0, children: _jsx(AlertActions, { banner: true, bodyStyle: { maxWidth: 1308, marginLeft: 'auto', marginRight: 'auto' }, message: _jsxs("div", { style: { display: 'flex' }, children: [_jsx(ExclamationCircleFilled, { "aria-hidden": "true", style: { fontSize: 21, marginTop: 3, marginRight: 8, color: '#1890FF' } }), _jsxs("div", { children: [_jsx(Typography.Paragraph, { style: { fontSize: isMobile ? 18 : 16, marginBottom: 6 }, strong: true, children: 'Sé parte de la comunidad Turingo' }), _jsx(Typography.Paragraph, { style: { fontSize: isMobile ? 16 : 14, marginBottom: 24 }, children: 'Conecta con otras personas emprendedoras, comparte experiencias y adquiere nuevos conocimientos.' })] })] }), onOk: _jsx(Button, { type: "text", style: { border: `1px solid${"var(--neutral-12)"}`, color: "var(--neutral-12)", height: 40, lineHeight: '22px' }, onClick: () => history.push(Router.Path.login({})), children: strings.screens.auth.login.action }) }) })), ((!isLogged && !isPurchase && !isB2BAuthPage) || error_code || isBlocked) && _jsx(FooterBar, { error: error_code })] }) })] }));
};
export { Application };
