import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useAppSelector } from '@hooks/useStore';
import { Row, Form } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { CertificateModal } from './CertificateModal';
import { CheckOne } from '@icon-park/react';
import { Certificate as IconCertificate } from '@icon-park/react';
import { FooterModal } from '@components/modals/components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import strings from '@resources/localization';
import { ReviewForm } from '../posts/post_subtypes/reviews/components/ReviewForm';
import useCommunity from '@hooks/useCommunity';
import style from '../certificate/Certificate.scss';
const CertificateCongratsModal = (props) => {
    const { open, kind } = props;
    const [reviewModal, setReviewModal] = useState(false);
    const [grantCertificateModal, setGrantCertificateModal] = useState(false);
    const [certificateModal, setCertificateModal] = useState(false);
    const { cm_pk } = useCommunity();
    const { b_pk } = useParams();
    const congratsStrings = strings.screens.boards.course.certificate.congrats;
    const ratesState = useAppSelector((state) => { var _a, _b, _c; return (_c = (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.boards.detail[b_pk]) === null || _b === void 0 ? void 0 : _b.rates) === null || _c === void 0 ? void 0 : _c.createState; });
    const [form] = Form.useForm();
    const onRateCreated = () => {
        setReviewModal(false);
        setGrantCertificateModal(true);
    };
    useMemo(() => {
        if (kind == 'certificate') {
            setGrantCertificateModal(open);
        }
        else {
            setReviewModal(open);
        }
    }, [open]);
    return (_jsxs(Row, { children: [_jsxs(TuringoModal, { width: 572, closable: false, title: null, open: reviewModal, onCancel: () => setReviewModal(false), footer: _jsx(FooterModal, { primary: {
                        action: form.submit,
                        loading: ratesState === null || ratesState === void 0 ? void 0 : ratesState.loading,
                        customText: strings.general.send,
                    } }, 'footer_modal'), children: [_jsx(CheckOne, { theme: "outline", size: "56", fill: "#13C2C2" }), _jsx(Paragraph, { style: { marginTop: 18, marginBottom: 16, fontWeight: 500 }, children: strings.screens.boards.course.finished }), _jsx(ReviewForm, { type: 'create', form: form, onFinish: onRateCreated, cm_pk: cm_pk, b_pk: b_pk })] }), _jsxs(TuringoModal, { width: 572, title: null, open: grantCertificateModal, onCancel: () => setGrantCertificateModal(false), footer: _jsx(FooterModal, { primary: {
                        action: () => {
                            setGrantCertificateModal(false);
                            setCertificateModal(true);
                        },
                        customText: congratsStrings.get,
                    } }, 'footer_modal'), bodyStyle: { textAlign: 'start' }, children: [_jsx("div", { className: style.icon, children: _jsx(IconCertificate, { fill: "#13C2C2", size: 64, className: style.icon }) }), _jsx(Paragraph, { strong: true, children: congratsStrings.title }), _jsx(Paragraph, { children: congratsStrings.description })] }), _jsx(CertificateModal, { download: true, open: certificateModal, hide: () => setCertificateModal(false) })] }));
};
export { CertificateCongratsModal };
