import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Progress } from 'antd';
import { formatBytes } from '@util/string/Functions';
import { Colors } from '@util/color/Colors';
import { DeleteOutlined } from '@ant-design/icons';
import { CheckOne } from '@icon-park/react';
import { GetTypeIcon } from '@components/content/posts/post_subtypes/attachments/AttachmentComponent';
export const FileComponent = (props) => {
    var _a;
    const { file, onRemove, isEditing } = props;
    const type = (_a = file.file) === null || _a === void 0 ? void 0 : _a.type;
    return (_jsxs("div", { children: [_jsxs("div", { style: { position: 'relative' }, children: [onRemove && !isEditing && (_jsx(Button, { onClick: onRemove, shape: "circle", icon: _jsx(DeleteOutlined, { "aria-hidden": "true", style: { display: 'flex', justifyContent: 'center' } }), style: { position: 'absolute', zIndex: 100, right: 14, top: 14 } })), _jsxs("div", { style: {
                            backgroundColor: Colors('FORM_INPUT_AREA_BACKGROUND'),
                            height: 68,
                            borderRadius: 4,
                            display: 'flex',
                            alignItems: 'center',
                        }, children: [_jsxs("div", { style: { position: 'relative' }, children: [GetTypeIcon(type, { fontSize: 36, padding: 16, color: 'var(--neutral-7)' }), file.status == 'success' && (_jsx(CheckOne, { theme: "two-tone", size: "16", fill: ['#ffffff', 'var(--polar-green-5)'], strokeLinejoin: "bevel", style: { position: 'absolute', bottom: 5, right: 13 } }))] }), _jsxs("div", { style: { display: 'flex', flexDirection: 'column', paddingRight: 50, wordBreak: 'break-all' }, children: [_jsx("div", { style: { fontWeight: 500, lineHeight: '22px' }, children: file.file.name }), _jsx("div", { style: { fontSize: 12, lineHeight: '20px' }, children: formatBytes(file.file.size) })] })] })] }), file.status == 'uploading' && (_jsx(Progress, { showInfo: false, type: "line", percent: file.percent, strokeWidth: 6, success: { strokeColor: 'var(--ant-primary-color)' }, strokeColor: { from: 'var(--ant-primary-color)', to: 'var(--ant-primary-color)' } }))] }));
};
