import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Typography } from 'antd';
import { addWebp } from '@util/browser/addWebp';
import { EventDetails } from '@screens/community/event/EventDetails';
import { Colors } from '@util/color/Colors';
import { CalendarOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Router } from '@Router';
import useCommunity from '@hooks/useCommunity';
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap';
const PreviewEvent = (props) => {
    const { item, clickeable } = props;
    const { cm_pk } = useCommunity();
    if (!item)
        return _jsx(_Fragment, {});
    return (_jsx(ConditionalWrap, { condition: clickeable, wrap: (children) => _jsx(Link, { to: Router.Path.event_details({ cm_pk, e_pk: item.publicKey }), children: children }), children: _jsxs("div", { style: {
                display: 'grid',
                gridTemplateColumns: '1fr',
                marginTop: 20,
                color: 'var(--neutral-13)',
            }, children: [_jsxs("div", { style: { paddingTop: '40%', position: 'relative' }, children: [item.bannerUrl && (_jsx("img", { alt: "example", style: { objectFit: 'cover', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }, src: addWebp(item.bannerUrl), loading: "lazy" })), !item.bannerUrl && (_jsx("div", { style: {
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'var(--image-empty-background)',
                            }, children: _jsx(CalendarOutlined, { style: { fontSize: 56, alignSelf: 'center', color: 'var(--neutral-6)' } }) }))] }), _jsx("div", { style: {
                        padding: 16,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: Colors('POSTED_POLL_BACKGROUND'),
                    }, children: _jsxs("div", { children: [_jsxs(Typography.Paragraph, { style: { marginBottom: 8 }, strong: true, children: [item.title, ' '] }), _jsx(EventDetails, { item: item, compact: true })] }) })] }) }));
};
export { PreviewEvent };
