import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Icon from '@ant-design/icons';
export const CatalogueItem10 = (props) => {
    return (_jsx(Icon, Object.assign({ "aria-hidden": "true", component: () => {
            if (props.filled) {
                return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.45459 0C8.00688 0 8.45459 0.447715 8.45459 1V3H11V1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V3H15.5454V1C15.5454 0.447715 15.9931 0 16.5454 0C17.0977 0 17.5454 0.447715 17.5454 1V3H19C20.1046 3 21 3.89544 21 5V6.4541H23C23.5523 6.4541 24 6.90182 24 7.4541C24 8.00639 23.5523 8.4541 23 8.4541H21V11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H21V15.5459H23C23.5523 15.5459 24 15.9936 24 16.5459C24 17.0982 23.5523 17.5459 23 17.5459H21V19C21 20.1046 20.1046 21 19 21H17.5454V23C17.5454 23.5523 17.0977 24 16.5454 24C15.9931 24 15.5454 23.5523 15.5454 23V21H13V23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23V21H8.45459V23C8.45459 23.5523 8.00688 24 7.45459 24C6.90231 24 6.45459 23.5523 6.45459 23V21H5C3.89544 21 3 20.1046 3 19V17.5459H1C0.447715 17.5459 0 17.0982 0 16.5459C0 15.9936 0.447715 15.5459 1 15.5459H3V13H1C0.447715 13 0 12.5523 0 12C0 11.4477 0.447715 11 1 11H3V8.4541H1C0.447715 8.4541 0 8.00639 0 7.4541C0 6.90182 0.447715 6.4541 1 6.4541H3V5C3 3.89543 3.89543 3 5 3H6.45459V1C6.45459 0.447715 6.90231 0 7.45459 0ZM9 8C8.44772 8 8 8.44772 8 9V15C8 15.5523 8.44772 16 9 16H15C15.5523 16 16 15.5523 16 15V9C16 8.44772 15.5523 8 15 8H9Z" }) }));
            }
            else {
                return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9 8C8.44771 8 8 8.44771 8 9V15C8 15.5523 8.44771 16 9 16H15C15.5523 16 16 15.5523 16 15V9C16 8.44771 15.5523 8 15 8H9ZM10 14V10H14V14H10Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.45459 0C8.00688 0 8.45459 0.447715 8.45459 1V3H11V1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V3H15.5454V1C15.5454 0.447715 15.9931 0 16.5454 0C17.0977 0 17.5454 0.447715 17.5454 1V3H19C20.1046 3 21 3.89544 21 5V6.4541H23C23.5523 6.4541 24 6.90182 24 7.4541C24 8.00639 23.5523 8.4541 23 8.4541H21V11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H21V15.5459H23C23.5523 15.5459 24 15.9936 24 16.5459C24 17.0982 23.5523 17.5459 23 17.5459H21V19C21 20.1046 20.1046 21 19 21H17.5454V23C17.5454 23.5523 17.0977 24 16.5454 24C15.9931 24 15.5454 23.5523 15.5454 23V21H13V23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23V21H8.45459V23C8.45459 23.5523 8.00688 24 7.45459 24C6.90231 24 6.45459 23.5523 6.45459 23V21H5C3.89544 21 3 20.1046 3 19V17.5459H1C0.447715 17.5459 0 17.0982 0 16.5459C0 15.9936 0.447715 15.5459 1 15.5459H3V13H1C0.447715 13 0 12.5523 0 12C0 11.4477 0.447715 11 1 11H3V8.4541H1C0.447715 8.4541 0 8.00639 0 7.4541C0 6.90182 0.447715 6.4541 1 6.4541H3V5C3 3.89543 3.89543 3 5 3H6.45459V1C6.45459 0.447715 6.90231 0 7.45459 0ZM19 5H5V19H19V5Z" })] }));
            }
        } }, props)));
};
