import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useAppSelector } from '@hooks/useStore';
import { useHistory } from 'react-router';
import { Router } from '@Router';
import { AvatarTuringo } from '@components/avatar/Avatar';
import useBreakpoints from '@hooks/useBreakpoints';
import Sider from 'antd/lib/layout/Sider';
import { Badge, Tooltip } from 'antd';
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap';
const SidebarCommunities = (props) => {
    const { onChange, kind, communities } = props;
    const history = useHistory();
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const { general } = useAppSelector((state) => state.notification);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const communityCopy = [...(communities || [])];
    const component = communityCopy
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((community) => {
        var _a, _b, _c, _d, _e, _f;
        return (_jsx(ConditionalWrap, { condition: !isMobile, wrap: (children) => (_jsx(Tooltip, { zIndex: 10, title: community.name, placement: "right", children: children })), children: _jsx(Badge, { count: ((_b = (_a = general.aggregate[community.publicKey]) === null || _a === void 0 ? void 0 : _a.notifications) === null || _b === void 0 ? void 0 : _b.newly) + ((_d = (_c = general.aggregate[community.publicKey]) === null || _c === void 0 ? void 0 : _c.messages) === null || _d === void 0 ? void 0 : _d.newly) || 0, color: 'var(--notification-dot)', children: _jsx(AvatarTuringo, { onClick: () => {
                        if (onChange)
                            onChange();
                        history.push(Router.Path.community_home({ cm_pk: community.publicKey }));
                    }, avatar: community.logoUrl, size: 16, width: isMobile ? 44 : 32, height: isMobile ? 44 : 32, isSelected: currentCommunity == community.publicKey, style: {
                        lineHeight: isMobile ? '44px' : '32px',
                        border: currentCommunity == community.publicKey ? '1px solid var(--ant-primary-color)' : '1px solid var(--neutral-1)',
                        cursor: 'pointer',
                    }, color: (_f = (_e = community.ui) === null || _e === void 0 ? void 0 : _e.style) === null || _f === void 0 ? void 0 : _f.color, name: community.name, isSidebar: true }) }) }, community.publicKey));
    });
    if (kind == 'sidebar') {
        return (_jsx(Sider, { className: "community", role: "complementary", "aria-labelledby": "sidebar-community", "aria-label": "Community navigation", tabIndex: 0, style: {
                overflow: 'auto',
                height: 'calc(100dvh - 48px)',
                backgroundColor: 'var(--sidebar)',
            }, width: 64, collapsedWidth: 64, trigger: null, collapsed: false, children: _jsx("div", { style: { marginTop: 16, display: 'grid', alignSelf: 'center', gridTemplateColumns: '1fr', gap: 16 }, children: component }) }));
    }
    else {
        return (_jsx("div", { style: { backgroundColor: 'var(--sidebar)', display: 'inline', gap: 16, maxWidth: 72, minWidth: 72, width: 72 }, children: _jsx("div", { style: {
                    display: 'grid',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: 16,
                    gap: 16,
                    paddingBottom: 16,
                    maxHeight: '100dvh',
                    overflow: 'auto',
                }, children: component }) }));
    }
};
export { SidebarCommunities };
