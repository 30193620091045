import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useAppSelector } from '@hooks/useStore';
import { Redirect } from 'react-router';
import { Router } from '@Router';
import useCommunity from '@hooks/useCommunity';
import { CommunityLoginPage } from '@screens/index';
import { getCurrentCommunity } from '@util/community/getCurrent';
export const getFirstCommunity = (to, communities) => {
    const copyCommunities = communities.items ? [...communities.items] : [];
    const communitiesOrdered = copyCommunities === null || copyCommunities === void 0 ? void 0 : copyCommunities.sort((a, b) => a.name.localeCompare(b.name));
    if (to) {
        return to;
    }
    if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) == 1) {
        return communitiesOrdered[0].publicKey;
    }
    else if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo');
        if (communityWithoutTuringo.length == 0) {
            return 'turingo';
        }
        else {
            return communityWithoutTuringo[0].publicKey;
        }
    }
};
export const redirectFunction = (to, communities) => {
    const copyCommunities = communities.items ? [...communities.items] : [];
    const communitiesOrdered = copyCommunities === null || copyCommunities === void 0 ? void 0 : copyCommunities.sort((a, b) => a.name.localeCompare(b.name));
    if (to) {
        return to;
    }
    if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) == 1) {
        return Router.Path.community_home({ cm_pk: communitiesOrdered[0].publicKey });
    }
    else if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo');
        if (communityWithoutTuringo.length == 0) {
            return Router.Path.community_home({ cm_pk: 'turingo' });
        }
        else {
            return Router.Path.community_home({ cm_pk: communityWithoutTuringo[0].publicKey });
        }
    }
};
export const RedirectComponent = (props) => {
    const communities = useAppSelector((state) => state.auth.communities);
    const copyCommunities = communities.items ? [...communities.items] : [];
    const communitiesOrdered = copyCommunities === null || copyCommunities === void 0 ? void 0 : copyCommunities.sort((a, b) => a.name.localeCompare(b.name));
    const { to } = props;
    if (to) {
        return _jsx(Redirect, { to: to });
    }
    if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) == 1) {
        return _jsx(Redirect, { to: { pathname: Router.Path.community_home({ cm_pk: communitiesOrdered[0].publicKey }) } });
    }
    else if ((communitiesOrdered === null || communitiesOrdered === void 0 ? void 0 : communitiesOrdered.length) > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo');
        if (communityWithoutTuringo.length == 0) {
            return _jsx(Redirect, { to: { pathname: Router.Path.community_home({ cm_pk: 'turingo' }) } });
        }
        else {
            return _jsx(Redirect, { to: { pathname: Router.Path.community_home({ cm_pk: communityWithoutTuringo[0].publicKey }) } });
        }
    }
    return _jsx(_Fragment, {});
};
export const RedirectLogin = () => {
    const { cm_pk } = useCommunity();
    if (getCurrentCommunity())
        return _jsx(CommunityLoginPage, {});
    useEffect(() => {
        const host = window.location.host;
        const hostname = window.location.hostname;
        const parts = host.split('.');
        const subdomain = parts.length > 2 ? parts[0] : null;
        const urlNoSubdomain = parts.slice(1, parts.length).join('.');
        const isDev = subdomain && subdomain.endsWith('dev');
        let baseUrl = `${cm_pk}.` + host;
        if (!isDev) {
            if (subdomain)
                baseUrl = `${cm_pk}.` + urlNoSubdomain;
            else
                baseUrl = `${cm_pk}.` + host;
        }
        else {
            if (subdomain || (parts.length > 1 && hostname.endsWith('localhost')))
                baseUrl = `${cm_pk}-dev.` + urlNoSubdomain;
            else
                baseUrl = `${cm_pk}-dev.` + host;
        }
        const redirectUrl = `http://${baseUrl}/login`;
        try {
            window.location.replace(redirectUrl);
        }
        catch (e) {
            window.location.href = redirectUrl;
        }
    }, [cm_pk]);
    return null;
};
