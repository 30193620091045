import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Alert, Button, Card, Col } from 'antd';
import strings from '@resources/localization';
import Example from '@bundle/assets/certificate/placeholder/placeholder-cert-turingo-basic.png';
import ExampleB2B from '@bundle/assets/certificate/placeholder/placeholder-cert-turingo-b2b.png';
import ExamplePremium from '@bundle/assets/certificate/placeholder/placeholder-cert-turingo-premium.png';
import useBreakpoints from '@hooks/useBreakpoints';
import { CertificateModal } from '@components/content/certificate_supplementary/CertificateModal';
import { useParams } from 'react-router';
import useCommunity from '@hooks/useCommunity';
const CertificateCard = (props) => {
    const { isStarted, pricing } = props;
    const [certVisible, setCertVisible] = useState(false);
    const { entityPk, id } = props;
    const { cm_pk } = useCommunity();
    const { b_pk } = useParams();
    if (!entityPk)
        return null;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isTuringo = cm_pk == 'turingo';
    const certificateStrings = strings.screens.boards.course.certificate;
    const showCertificate = () => {
        setCertVisible(true);
    };
    const hideCertificate = () => {
        setCertVisible(false);
    };
    const isPremium = pricing == 'priced';
    return (_jsx(Col, { span: 24, children: _jsxs(Card, { id: id, bordered: false, title: certificateStrings.download.title, bodyStyle: { textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }, children: [_jsx(Alert, { className: 'blue', style: { alignItems: 'baseline', textAlign: 'start' }, message: certificateStrings.download.message, type: "info", closable: true }), _jsx("div", { style: { height: 138, width: 176, margin: 10 }, children: _jsx("img", { src: isTuringo ? isPremium ? ExamplePremium : Example : ExampleB2B, height: 138, width: 176, style: { border: isPremium ? 'none' : '1px solid #F0F0F0' }, alt: "Certificado de ejemplo", loading: "lazy" }) }), _jsx(Button, { block: isMobile, onClick: showCertificate, children: isStarted ? certificateStrings.see : certificateStrings.example }), _jsx(CertificateModal, { download: true, open: certVisible, hide: hideCertificate })] }) }));
};
export { CertificateCard };
