const digestName = (name, limit = 10000) => {
    if (!name || typeof name !== 'string')
        return 420;
    let accumulator = 0;
    for (let index = 0; index < name.length; index++) {
        accumulator = (accumulator + name.charCodeAt(index) * index) % limit;
    }
    return accumulator;
};
const ColorTable = Object.freeze([
    { banner: '#57B7FF', avatar: '#0076D1' },
    { banner: '#99DBDA', avatar: '#2D807E' },
    { banner: '#FDB05E', avatar: '#B65F02' },
    { banner: '#FF8AD8', avatar: '#E00096' },
    { banner: '#6D6DD5', avatar: '#3131AA' },
    { banner: '#FF9EB3', avatar: '#E60032' },
    { banner: '#FDB05E', avatar: '#B65F02' },
    { banner: '#6D6DD5', avatar: '#3131AA' },
    { banner: '#FF8AD8', avatar: '#E00096' },
]);
export const entityColor = (name) => {
    return ColorTable[digestName(name) % ColorTable.length];
};
