import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { BackTop, Button, Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/useStore';
import useBreakpoints from '@hooks/useBreakpoints';
import style from './CreationBox.scss';
import strings from '@resources/localization';
import { lockScroll, unlockScroll } from '@hooks/useBlockScroll';
import { useHistory } from 'react-router';
import { ButtonPost } from 'src/turingo_components/buttons/button_post/ButtonPost';
export const CreatePost = (props) => {
    const { setPostModal, setPostStep, setPostType } = props;
    const history = useHistory();
    const [locationKeys, setLocationKeys] = useState([]);
    useEffect(() => {
        return history.listen((location) => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key]);
            }
            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                    showPostModal('basic', 'creation');
                }
                else {
                    setLocationKeys((keys) => [location.key, ...keys]);
                    unlockScroll();
                    setPostStep('creation');
                    setPostType('basic');
                    setPostModal(false);
                }
            }
        });
    }, [locationKeys]);
    const showPostModal = (type, step = 'creation') => {
        lockScroll();
        setPostStep(step);
        setPostType(type);
        setPostModal(true);
        history.push(history.location.pathname, { hash: 'create' });
    };
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs(_Fragment, { children: [isMobile && (_jsx(BackTop, { visibilityHeight: 720, onClick: () => showPostModal('basic'), style: { zIndex: 100, right: 24, bottom: 24 }, children: _jsx(Button, { type: "primary", shape: "circle", style: { height: 44, width: 44, boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)' }, icon: _jsx(PlusOutlined, { size: 24 }) }) })), _jsx(Card, { bodyStyle: { padding: isMobile ? '16px 16px 16px 16px' : '24px 24px 16px 24px' }, bordered: false, children: _jsx(Row, { gutter: 24, justify: 'center', align: 'middle', children: _jsxs(Col, { flex: "auto", children: [_jsxs("div", { style: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: isMobile ? 0 : 24 }, children: [_jsx(AvatarTuringo, { size: 16, style: { height: 40, width: 40, minWidth: 40, marginRight: 8, lineHeight: '40px' }, name: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.name, avatar: currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.avatarUrl }), _jsx("div", { style: { flexGrow: 1 }, children: _jsx(Button, { className: style.createButton, style: {
                                                width: '100%',
                                                color: "var(--neutral-7)",
                                                textAlign: 'start',
                                                height: isMobile ? 44 : 40,
                                            }, onClick: () => showPostModal('basic'), children: strings.screens.boards.discussion.comment.placeholder }) }), isMobile && _jsx(ButtonPost, { style: { marginLeft: 16 }, hideTitle: true, onClick: () => showPostModal('image'), kind: 'image' })] }), !isMobile && (_jsxs("div", { style: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(ButtonPost, { onClick: () => showPostModal('image'), kind: 'image' }), _jsx(ButtonPost, { onClick: () => showPostModal('video'), kind: 'video' }), _jsx(ButtonPost, { onClick: () => showPostModal('basic', 'poll_creation'), kind: 'poll' }), _jsx(ButtonPost, { onClick: () => showPostModal('attachment'), kind: 'attachment' })] }))] }) }) })] }));
};
