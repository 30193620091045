import { jsx as _jsx } from "react/jsx-runtime";
import { Collapse, Divider } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
const CollapseStyled = (props) => {
    const { children, ghost, accordion, defaultActiveKey, className } = props;
    return (_jsx(Collapse, { className: className, ghost: ghost, defaultActiveKey: defaultActiveKey, accordion: accordion, expandIcon: ({ isActive }) => (isActive ? _jsx(MinusOutlined, { "aria-label": "Minimaze panel", style: { color: 'var(--ant-primary-color)' } }) : _jsx(PlusOutlined, { "aria-label": "Maximaze panel", style: { color: 'var(--ant-primary-color)' } })), children: children }));
};
const DividerPanel = (props) => {
    return _jsx(Divider, { style: { marginTop: 0, marginBottom: 16 } });
};
export { CollapseStyled, DividerPanel };
