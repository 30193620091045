var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Input, InputNumber, Select } from 'antd';
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem';
import { urlRules } from '@components/content/entity/community_header/components/community_edit_modal/CommunityEditModal';
import strings from '@resources/localization';
import { FormDeferredItem } from '@components/form/FormDeferredItem/FormDeferredItem';
import { API } from '@api/API';
import { FormPhoneItem } from '@components/form/FormPhoneItem/FormPhoneItem';
import { useAppSelector } from '@hooks/useStore';
import { FormHelpItem } from '@components/form/HelpeableWrapper/FormHelpItem';
import { FormContext } from 'antd/es/form/context';
import dayjs from 'dayjs';
import DatePicker from '@components/form/DayJS/DatePicker';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localeEN from 'antd/es/date-picker/locale/en_US';
require('dayjs/locale/es');
require('dayjs/locale/en');
const dateRestrictions = (restriction, current) => {
    if (restriction == 'only_past_dates') {
        return current > dayjs().add(-1, 'days');
    }
    if (restriction == 'only_future_dates') {
        return dayjs() > current;
    }
    return undefined;
};
const CustomFormItem = (props) => {
    var _a, _b, _c, _d, _e;
    const language = useAppSelector((state) => state.ui.language);
    dayjs.locale(language);
    const locale = language == 'es' ? localeES : localeEN;
    const { property, entity } = props;
    const { form } = useContext(FormContext);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        const selectElement = document.getElementById(property.id);
        if (selectElement) {
            const inputs = selectElement.querySelectorAll('input');
            inputs.forEach((input) => {
                input.setAttribute('aria-expanded', `${isOpen}`);
            });
        }
    }, [isOpen]);
    if (property.type == 'array:opt:multi') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, name: ['memberProperties', property.id], label: property.title, children: _jsx(Select, { id: property.id, onDropdownVisibleChange: (open) => setIsOpen(open), getPopupContainer: (trigger) => trigger.parentElement, dropdownStyle: { zIndex: 2000 }, mode: "multiple", allowClear: true, filterOption: (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0, options: (_a = property.options) === null || _a === void 0 ? void 0 : _a.sort((a, b) => (property.optionOrder == 'az' ? a.title.localeCompare(b.title) : a.order - b.order)).map((option) => {
                    return { label: option.title, value: option.title };
                }), style: { width: '100%' } }) }, property.id));
    }
    else if (property.type == 'array:opt:single') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, name: ['memberProperties', property.id], label: property.title, children: _jsx(Select, { id: property.id, onDropdownVisibleChange: (open) => setIsOpen(open), getPopupContainer: (trigger) => trigger.parentElement, dropdownStyle: { zIndex: 2000 }, allowClear: true, filterOption: (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0, options: (_b = property.options) === null || _b === void 0 ? void 0 : _b.sort((a, b) => (property.optionOrder == 'az' ? a.title.localeCompare(b.title) : a.order - b.order)).map((option) => {
                    return { label: option.title, value: option.title };
                }), style: { width: '100%' } }) }, property.id));
    }
    else if (property.type == 'string:email') {
        return (_jsx(FormEmailItem, { help: property.hint, name: ['memberProperties', property.id], label: property.title, customErrors: {
                required: strings.general.fieldBlank,
            }, validations: ['format', 'long', 'short', ...(property.required ? ['required'] : [])] }, property.id));
    }
    else if (property.type == 'string') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, name: ['memberProperties', property.id], label: property.title, children: _jsx(Input.TextArea, { "aria-label": property.title, showCount: true, maxLength: 500 }) }, property.id));
    }
    else if (property.type == 'string:title') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, name: ['memberProperties', property.id], label: property.title, children: _jsx(Input, { "aria-label": property.title, showCount: true, maxLength: 30 }) }, property.id));
    }
    else if (property.type == 'string:url') {
        return (_jsx(FormHelpItem, { help: property.hint, name: ['memberProperties', property.id], label: property.title, rules: [...urlRules, ...(property.required ? [{ required: true, message: strings.general.fieldBlank }] : [])], children: _jsx(Input, { "aria-label": property.title }) }, property.id));
    }
    else if (property.type == 'geo:city:id') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, name: ['memberProperties', property.id], label: property.title, children: _jsx(FormDeferredItem, { name: ['memberProperties', property.id], onSearchDeferred: (term) => __awaiter(void 0, void 0, void 0, function* () {
                    const results = yield API.citySearch({ searchParams: { term } });
                    const data = results['data'];
                    if (!data || !data.cities)
                        return 'wait';
                    return data.cities.map((city) => ({
                        key: `city-${city.id}`,
                        label: `${city.name}, ${city.countryName}`,
                        value: `${city.id}`,
                    }));
                }), getPopupContainer: (trigger) => trigger.parentElement, placeholder: strings.screens.profile.editModal.location.placeholder, value: ((_c = entity === null || entity === void 0 ? void 0 : entity.memberProperties) === null || _c === void 0 ? void 0 : _c[property.id]) && (entity === null || entity === void 0 ? void 0 : entity.memberProperties[property.id].value).id
                    ? {
                        key: `city-${(entity === null || entity === void 0 ? void 0 : entity.memberProperties[property.id].value).id}`,
                        label: `${(entity === null || entity === void 0 ? void 0 : entity.memberProperties[property.id].value).name}, ${(entity === null || entity === void 0 ? void 0 : entity.memberProperties[property.id].value).countryName}`,
                        value: `${(entity === null || entity === void 0 ? void 0 : entity.memberProperties[property.id].value).id}`,
                    }
                    : undefined }) }, property.id));
    }
    else if (property.type == 'string:phone') {
        return (_jsx(FormPhoneItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, defaultValue: (_e = (_d = entity === null || entity === void 0 ? void 0 : entity.memberProperties) === null || _d === void 0 ? void 0 : _d[property.id]) === null || _e === void 0 ? void 0 : _e.value, label: property.title, name: ['memberProperties', property.id] }, property.id));
    }
    else if (property.type == 'integer') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, name: ['memberProperties', property.id], label: property.title, children: _jsx(InputNumber, { "aria-label": property.title, min: -999999999999999, pattern: "[0-9]", inputMode: "numeric", max: 999999999999999, type: "number", style: { width: '100%' } }) }, property.id));
    }
    else if (property.type == 'boolean') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, name: ['memberProperties', property.id], valuePropName: "checked", children: _jsxs(Checkbox, { "aria-label": property.title, defaultChecked: form.getFieldValue(['memberProperties', property.id]), children: [" ", property.title] }) }, property.id));
    }
    else if (property.type == 'string:YYYY-MM-DD') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, getValueProps: (e) => ({
                value: e ? dayjs(e) : '',
            }), name: ['memberProperties', property.id], label: property.title, children: _jsx(DatePicker, { disabledDate: (current) => {
                    return dateRestrictions(property.restriction, current);
                }, locale: locale, getPopupContainer: (trigger) => trigger.parentElement, placeholder: "", style: { width: '100%' }, format: 'DD/MM/YYYY' }) }));
    }
    else if (property.type == 'string:YYYY-MM-DD:maskyear') {
        return (_jsx(FormHelpItem, { rules: [{ required: property.required, message: strings.general.fieldBlank }], help: property.hint, getValueProps: (e) => ({
                value: e ? dayjs(e) : '',
            }), name: ['memberProperties', property.id], label: property.title, children: _jsx(DatePicker, { disabledDate: (current) => {
                    return dateRestrictions(property.restriction, current);
                }, locale: locale, getPopupContainer: (trigger) => trigger.parentElement, placeholder: "", style: { width: '100%' }, format: 'YYYY', picker: "year" }) }));
    }
    else
        return _jsx(_Fragment, {});
};
export { CustomFormItem };
