import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { postManyReaction } from '@state/actions';
import useCommunity from '@hooks/useCommunity';
import { useParams } from 'react-router';
import { ListPostItem } from './ListPostItem';
import { Divider } from 'antd';
export const ListPost = (props) => {
    const { items, divider } = props;
    const { cm_pk } = useCommunity();
    const { b_pk } = useParams();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const dispatch = useAppDispatch();
    const isFeed = !b_pk;
    useEffect(() => {
        if (items) {
            const handleScroll = () => {
                var _a, _b;
                const posts = document.querySelectorAll('.post');
                let postIdInView = null;
                posts.forEach((post) => {
                    const rect = post.getBoundingClientRect();
                    if (rect.top <= window.innerHeight / 2 || rect.bottom <= window.innerHeight) {
                        postIdInView = post.getAttribute('data-post-id');
                    }
                });
                if (postIdInView) {
                    const actual_post = (_a = items[postIdInView]) === null || _a === void 0 ? void 0 : _a.item;
                    if (actual_post && !((_b = actual_post.interactions) === null || _b === void 0 ? void 0 : _b.pinView)) {
                        seeDebounced({ boardPk: actual_post.boardPk, postPk: actual_post.publicKey });
                    }
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [items]);
    const see = (posts) => {
        if (isLogged) {
            void dispatch(postManyReaction({
                urlParams: { cm_pk },
                bodyParams: { postReferences: posts },
                extraParams: { scope: 'all', feed: isFeed },
            }));
        }
    };
    function debounceAgg(fn, wait = 3000) {
        let timeout = null;
        let update = [];
        function processUpdate() {
            timeout = null;
            fn(update);
            update = [];
        }
        return (value) => {
            if (!update.some((upt) => {
                return upt.boardPk == value.boardPk && upt.postPk == value.postPk;
            }))
                update = [...update, value];
            if (timeout == null)
                timeout = setTimeout(processUpdate, wait);
        };
    }
    const seeDebounced = debounceAgg(see);
    return (_jsx("div", { style: { display: 'grid', gridTemplateColumns: 'repeat(1, minmax(0, 1fr))', rowGap: 16, columnGap: 16 }, children: items &&
            Object.keys(items).map((key, index) => {
                var _a, _b, _c, _d, _e;
                if ((_a = items[key]) === null || _a === void 0 ? void 0 : _a.item) {
                    return (_jsxs("div", { className: "post", "data-post-id": (_c = (_b = items[key]) === null || _b === void 0 ? void 0 : _b.item) === null || _c === void 0 ? void 0 : _c.publicKey, children: [_jsx(ListPostItem, { item: items[key].item }), divider && index != Object.keys(items).length - 1 && (_jsx(Divider, { style: {
                                    width: '92%',
                                    minWidth: '92%',
                                    margin: '0px 0px 0px 4%',
                                } }))] }, (_e = (_d = items[key]) === null || _d === void 0 ? void 0 : _d.item) === null || _e === void 0 ? void 0 : _e.publicKey));
                }
            }) }));
};
