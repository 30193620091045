import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarTuringo } from '@components/avatar/Avatar';
const PostCardHeader = (props) => {
    var _a, _b, _c, _d;
    const { item, size = 'small' } = props;
    const sizeNumber = size == 'large' ? 160 : 80;
    const sizeFont = size == 'large' ? 64 : 32;
    return (_jsxs("div", { style: { position: 'relative', paddingBottom: '56.2%', borderRadius: '4px 4px 0 0' }, children: [!((_a = item.assets) === null || _a === void 0 ? void 0 : _a.thumbnailLargeUrl) && (_jsx("div", { style: {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    borderRadius: '4px 4px 0 0',
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'var(--neutral-4)',
                }, children: _jsx(AvatarTuringo, { kind: 'circle', name: item.title, avatar: (_b = item.assets) === null || _b === void 0 ? void 0 : _b.thumbnailSmallUrl, size: sizeFont, width: sizeNumber, height: sizeNumber, style: { lineHeight: `${sizeNumber}px` } }) })), ((_c = item.assets) === null || _c === void 0 ? void 0 : _c.thumbnailLargeUrl) && (_jsx("img", { alt: "thumbnail large", style: {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    borderRadius: '4px 4px 0 0',
                    top: 0,
                    left: 0,
                }, src: (_d = item.assets) === null || _d === void 0 ? void 0 : _d.thumbnailLargeUrl, loading: "eager" }))] }));
};
export { PostCardHeader };
