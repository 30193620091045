import { jsx as _jsx } from "react/jsx-runtime";
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
import strings from '@resources/localization';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { communityExit } from '@state/actions';
import useCommunity from '@hooks/useCommunity';
import { useHistory } from 'react-router';
import { Router } from '@Router';
export const CommunityExitModal = (props) => {
    const { open, onCancel } = props;
    const { cm_pk } = useCommunity();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const memberState = useAppSelector((state) => { var _a; return (_a = state.community.items[cm_pk].members) === null || _a === void 0 ? void 0 : _a.createState; });
    const afterAction = () => {
        history.push(Router.Path.home({})), onCancel();
    };
    const onDelete = () => {
        void dispatch(communityExit({
            urlParams: { cm_pk },
            options: {
                alerts: {
                    success: 'Has abandonado exitosamente esta comunidad.',
                },
                then: {
                    action: afterAction,
                },
            },
        }));
    };
    return (_jsx(TuringoModal, { onCancel: onCancel, open: open, title: null, width: 572, footer: [
            _jsx(FooterModal, { primary: {
                    action: onDelete,
                    customText: 'Abandonar',
                    loading: memberState === null || memberState === void 0 ? void 0 : memberState.editing,
                }, secondary: {
                    action: onCancel,
                    customText: strings.general.cancel,
                } }, 'footer_modal'),
        ], children: _jsx(InformationModalBody, { kind: 'warning', title: 'Abandonar comunidad', description: '¿Quieres dejar de ser parte de esta comunidad?', subdescription: 'Al hacerlo, ya no tendrás acceso a ninguno de sus espacios.' }) }));
};
