import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
import { Button, Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import { ShareAltOutlined, AppstoreOutlined } from '@ant-design/icons';
import useBreakpoints from '@hooks/useBreakpoints';
import ImagePlaceholder from '@bundle/assets/general/placeholder-image.png';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import strings from '@resources/localization';
import useCommunity from '@hooks/useCommunity';
import { Opt } from '@util/logical/OptionalChain';
import { FilterTopic } from '../filter_topic/FilterTopic';
export const Header = (props) => {
    const { cm_pk } = useCommunity();
    const { b_pk } = useParams();
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const board = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk]);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isSmall = breakpoints.isSmall;
    const openShareModal = () => {
        dispatch(modalAction({
            modal: 'share',
            active: true,
            link: window.location.href,
            e_kind: 'community',
            kind: 'community',
            referenceCommunity: cm_pk,
        }));
    };
    if (!board || !board.item)
        return _jsx(_Fragment, {});
    return (_jsx(_Fragment, { children: _jsxs(Card, { bodyStyle: { padding: isMobile ? 16 : '16px 24px' }, style: { borderRadius: '0px 0px 4px 4px' }, bordered: false, cover: _jsx("div", { children: _jsx("div", { style: { paddingTop: '40%', position: 'relative' }, children: _jsx("img", { alt: "example", style: {
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }, src: Opt(board.item.bannerUrl, community.item.bannerUrl, ImagePlaceholder), loading: "eager" }) }) }), children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(AppstoreOutlined, { style: { fontSize: 22, marginRight: 8 } }), _jsx(Title, { level: 4, style: { flexGrow: 1, wordWrap: 'break-word', fontWeight: 500, wordBreak: 'break-word', marginBottom: 0, alignSelf: 'center' }, children: board.item.name }), board.item.visibility == 'public' && isLogged && (_jsx(Button, { style: Object.assign(Object.assign({}, (!isSmall && { marginRight: 10, marginLeft: 10 })), { minWidth: 48 }), size: "large", icon: _jsx(ShareAltOutlined, {}), onClick: openShareModal, children: isMobile ? '' : strings.general.share }, `cb#2`))] }), props.filter && _jsx(FilterTopic, { topics: board.item.topics, children: " " })] }) }));
};
