import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Form, Input, Radio } from 'antd';
import { useAppDispatch } from '@hooks/useStore';
import strings from '@resources/localization';
import { rateLessonCreate } from '@state/actions';
import { useRequestState } from '@api/requests/AsyncRequests';
import { InformationModal } from './information_modal/InformationModal';
import { FooterModal } from './components/FooterModal';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useParams } from 'react-router';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import useCommunity from '@hooks/useCommunity';
export const RateLessonModal = (props) => {
    const { step, setStep } = props;
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { cm_pk } = useCommunity();
    const { b_pk, p_pk } = useParams();
    const onFinish = (res) => {
        setStep('success');
    };
    const [state, setState] = useRequestState(undefined, false, false);
    const submit = (values) => {
        const { rate, content } = values;
        void dispatch(rateLessonCreate({
            urlParams: { cm_pk, b_pk: b_pk, p_pk },
            bodyParams: {
                rate,
                content,
            },
            extraParams: {
                scope: 'detail',
            },
            options: {
                redux: {
                    stateHandler: setState,
                },
                alerts: {
                    error: strings.alerts.rate.create.failure,
                },
                then: {
                    action: onFinish,
                },
            },
        }));
    };
    const [value, setValue] = useState(undefined);
    const onChange = (e) => {
        setValue(e.target.value);
    };
    return (_jsxs(_Fragment, { children: [_jsx(InformationModal, { kind: 'success', open: step == 'success', onClick: () => setStep(undefined), title: 'Gracias', description: 'La comunidad la construimos en conjunto, por lo que tu opinión es muy importante para Turingo.' }), _jsx(TuringoModal, { open: step == 'form', width: 572, title: 'Calificación de la lección', onCancel: () => setStep(undefined), footer: [
                    _jsx(FooterModal, { primary: {
                            action: form.submit,
                            disabled: !value,
                            loading: state.editing,
                            customText: strings.general.send,
                        } }, 'footer_modal'),
                ], children: _jsxs(Form, { form: form, onFinish: submit, layout: 'vertical', children: [_jsx(Form.Item, { name: ['rate'], label: _jsx(Paragraph, { children: 'Del 0 al 10 ¿qué tan probable es que recomiendes esta lección a otra persona?' }), children: _jsx(Radio.Group, { onChange: onChange, prefixCls: "radio-turingo", name: "radiogroup", children: Array(11)
                                    .fill(0)
                                    .map((a, i) => {
                                    return (_jsx(Radio, { prefixCls: "radio-turingo", value: i, children: i }, `rate#${i}`));
                                }) }) }), _jsx(Form.Item, { name: ['content'], label: 'Cuéntanos qué te pareció la lección', children: _jsx(Input.TextArea, { autoSize: { minRows: 3, maxRows: 3 }, showCount: true, maxLength: 500 }) })] }) })] }));
};
