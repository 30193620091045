import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { entityColor } from '@util/color/EntityColorHash';
import { useAppSelector } from '@hooks/useStore';
import { addWebp } from '@util/browser/addWebp';
import { tableColor } from '@state/reducers/UserInterface/UserInterfaceReducer';
const AvatarTuringo = (props) => {
    var _a, _b, _c;
    const { avatar, name, className, style, size = 'small', height, width, themeForced, kind = 'circle', publicKey, onClick, color, isSelected, isSidebar, conteinerStyle, } = props;
    const isBase64 = (_a = avatar === null || avatar === void 0 ? void 0 : avatar.startsWith) === null || _a === void 0 ? void 0 : _a.call(avatar, 'data:');
    const nameList = name ? name.split(' ') : ['N', 'A'];
    const theme = themeForced ? themeForced : useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    if (kind == 'circle') {
        return (_jsxs("div", { style: Object.assign({ position: 'relative' }, conteinerStyle), children: [!isSelected && isSidebar && (_jsx("div", { onClick: onClick, style: {
                        position: 'absolute',
                        top: 1,
                        left: 1,
                        height: height - 2,
                        width: width - 2,
                        borderRadius: 100,
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
                        zIndex: 2,
                        cursor: 'pointer',
                    } })), isBase64 && (_jsx("img", { alt: `Avatar of ${name}`, className: className, style: Object.assign({ borderRadius: 100, minWidth: width, height: height, width: width }, style), src: avatar })), !isBase64 && avatar && (_jsx("img", { alt: `Avatar of ${name}`, onClick: onClick, className: className, style: Object.assign(Object.assign({ borderRadius: 100, minWidth: width, height: height, width: width, fontSize: size, backgroundColor: color ? tableColor[color] : avatar ? 'transparent' : entityColor(publicKey ? name + publicKey : name).avatar }, style), (size != 'small' && { display: 'flex', alignItems: 'center' })), src: avatar ? addWebp(avatar) : undefined })), !isBase64 && !avatar && (_jsx("div", { "aria-hidden": "true", onClick: onClick, className: className, style: Object.assign(Object.assign({ borderRadius: 100, minWidth: width, height: height, width: width, fontSize: size, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: color ? tableColor[color] : avatar ? 'transparent' : entityColor(publicKey ? name + publicKey : name).avatar }, style), (size != 'small' && { display: 'flex', alignItems: 'center' })), children: _jsxs("div", { "aria-hidden": "true", style: { fontSize: size, color: 'white' }, children: [nameList.length >= 1 && ((_b = nameList[0][0]) === null || _b === void 0 ? void 0 : _b.toUpperCase()), nameList.length > 1 && ((_c = nameList[1][0]) === null || _c === void 0 ? void 0 : _c.toUpperCase())] }) }))] }));
    }
    else {
        return (_jsx("div", { role: 'img', "aria-label": `Avatar of ${name}`, style: {
                height: height,
                width: width,
                minWidth: width,
                backgroundImage: `url(${addWebp(avatar)})`,
                backgroundSize: 'cover',
                backgroundPositionX: isDark ? 240 : -60,
                marginTop: 20,
                marginBottom: 20,
            } }));
    }
};
export { AvatarTuringo };
