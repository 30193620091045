import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import strings from '@resources/localization';
import { Router } from '@Router';
import { createNodes } from '@util/string/Functions';
import Paragraph from 'antd/lib/typography/Paragraph';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@hooks/useStore';
export const Content = (props) => {
    const { style, text, mentions, color } = props;
    const [expanded, setExpanded] = useState(false);
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const nRows = 7;
    const onClose = () => {
        setExpanded(false);
    };
    const onExpand = () => {
        setExpanded(true);
    };
    const componentDecorator = (href, text, key) => (_jsx("div", { role: "link", tabIndex: 0, "aria-labelledby": `link-${key}`, onClick: () => window.open(href, '_blank', 'noopener,noreferrer'), onKeyPress: (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                window.open(href, '_blank', 'noopener,noreferrer');
            }
        }, style: {
            display: 'inline-block',
            color: 'var(--ant-primary-color)',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            cursor: 'pointer',
            outline: 'none',
        }, className: "linkify__text interactive-div", id: `link-${key}`, children: text }, key));
    const tokens = mentions && mentions.length > 0 ? createNodes(mentions, text) : null;
    const contents = _.map(tokens, (token, i) => {
        if (token.type == 'mention' && currentCommunity) {
            return (_jsx(Link, { to: Router.Path.user_details({ u_pk: token.publicKey, cm_pk: currentCommunity }), style: { color: 'var(--ant-primary-color)', fontWeight: 500 }, children: token.data }, i));
        }
        else {
            return token.data;
        }
    });
    const hasMoreRows = (text.match(/\n/g) || '').length + 1 >= nRows;
    const ref = useRef();
    return (_jsxs("div", { ref: ref, style: style, children: [_jsx(Paragraph, { style: { whiteSpace: 'pre-wrap', marginBottom: 0, overflowWrap: 'break-word', wordBreak: 'break-word', color: color }, ellipsis: !expanded && hasMoreRows ? { rows: nRows } : false, children: _jsxs(Linkify, { componentDecorator: componentDecorator, children: [tokens ? contents : text, " "] }) }), !expanded && hasMoreRows && _jsx("a", { onClick: onExpand, children: strings.general.seeMoreText }), ' ', ' '] }));
};
