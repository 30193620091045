function getCurrentCommunity() {
    const host = window.location.host;
    const hostname = window.location.hostname;
    const parts = host.split('.');
    const subdomain = parts.length > 2 || (parts.length > 1 && hostname.endsWith('localhost')) ? parts[0] : null;
    const isDev = subdomain === null || subdomain === void 0 ? void 0 : subdomain.endsWith('dev');
    let cm_pk = null;
    if (!isDev) {
        if (subdomain)
            cm_pk = subdomain;
    }
    else {
        if (subdomain && subdomain.endsWith('-dev')) {
            cm_pk = subdomain.slice(0, -4);
        }
    }
    return cm_pk;
}
export { getCurrentCommunity };
