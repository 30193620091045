import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { useHistory } from 'react-router';
import { Router } from '@Router';
import { FooterModal } from './components/FooterModal';
import { InformationModalBody } from './information_modal/InformationModalBody';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
export const SeeMoreModal = () => {
    const seeMoreModal = useAppSelector((state) => state.modal.see_more);
    const dispatch = useAppDispatch();
    const closeModal = () => {
        dispatch(modalAction({ modal: 'see_more', state: false }));
    };
    const onLogin = () => {
        history.push(Router.Path.login({}));
        dispatch(modalAction({ modal: 'see_more', state: false }));
    };
    const history = useHistory();
    return (_jsx(TuringoModal, { open: seeMoreModal, onCancel: closeModal, width: 600, footer: [
            _jsx(FooterModal, { secondary: {
                    action: onLogin,
                    customText: strings.screens.auth.login.action,
                } }, 'footer_modal'),
        ], children: _jsx(InformationModalBody, { kind: 'warning', title: 'Únete a la comunidad', description: 'Podrás acceder a las publicaciones de otras personas emprendedoras.' }) }));
};
