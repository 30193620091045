import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useAppSelector } from '@hooks/useStore';
import { Button, Card, Skeleton, Alert } from 'antd';
import Text from 'antd/lib/typography/Text';
import { API } from '@api/API';
import { getRequest, useRequestState } from '@api/requests/AsyncRequests';
import { CertificateModal } from './CertificateModal';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import { useParams } from 'react-router';
import useCommunity from '@hooks/useCommunity';
const CertificateListCard = (props) => {
    var _a, _b, _c;
    const { userPk, isPrivate } = props;
    const { cm_pk } = useCommunity();
    const { u_pk } = useParams();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const isLoggedUser = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.publicKey) == u_pk;
    const [certificates, setCertificates] = useRequestState([], false);
    if ((!isPrivate && currentEntity) || (isPrivate && isLoggedUser)) {
        void getRequest(API.userMerit, setCertificates, [userPk])({ urlParams: { cm_pk, u_pk: userPk } });
    }
    const [certVisible, setCertVisible] = useState(false);
    const [currentCert, setCurrentCert] = useState();
    const showCertificate = (certificate) => {
        setCurrentCert(certificate.publicKey);
        setCertVisible(true);
    };
    const hideCertificate = () => {
        setCertVisible(false);
    };
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs(Card, { bordered: false, title: strings.screens.profile.certificates.title, children: [certificates.loading && _jsx(Skeleton, { title: false, active: true, paragraph: { rows: 5 } }), ((_a = certificates.data) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx("div", { style: { display: 'grid', rowGap: 8 }, children: certificates.data.map((certificate, index) => (_jsx(Button, { size: isMobile ? 'large' : 'middle', block: true, className: "sideBarBtn", style: {
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }, onClick: () => showCertificate(certificate), children: certificate.board.name }, index.toString()))) })), ((_b = certificates.data) === null || _b === void 0 ? void 0 : _b.length) == 0 && isLoggedUser && (_jsx(Text, { style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.certificates.description_owner })), ((_c = certificates.data) === null || _c === void 0 ? void 0 : _c.length) == 0 && !isLoggedUser && currentEntity && (_jsx(Text, { style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.certificates.description_empty })), !currentEntity && _jsx(Alert, { message: 'Conoce a otras personas emprendedoras ¡Inicia sesión en Turingo!' }), _jsx(CertificateModal, { download: isLoggedUser, open: certVisible, hide: hideCertificate, certificate: currentCert })] }, "1"));
};
export { CertificateListCard };
