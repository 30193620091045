import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { Button, Skeleton, Typography } from 'antd';
import dayjs from 'dayjs';
import { LinkUnderline } from '@components/buttons/link_underline/LinkUnderline';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { useAppSelector } from '@hooks/useStore';
import { Router } from '@Router';
import { DownOutlined } from '@ant-design/icons';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import Paragraph from 'antd/lib/typography/Paragraph';
import { TuringoTag } from '@components/content/tag/TuringoTag';
import { capitalizeFirstLetter } from '@util/string/Functions';
import { PostContext } from '../Post';
import { useParams } from 'react-router';
import { CheckOne, CloseOne } from '@icon-park/react';
import { MonikerComponent } from './MonikerComponent';
import { v4 } from 'uuid';
import useCommunity from '@hooks/useCommunity';
export const getMonikerTitle = (user, maxWidth) => {
    var _a;
    const firstKey = (user === null || user === void 0 ? void 0 : user.memberProperties) ? Object.keys(user === null || user === void 0 ? void 0 : user.memberProperties)[0] : undefined;
    const moniker = firstKey ? (_a = user === null || user === void 0 ? void 0 : user.memberProperties) === null || _a === void 0 ? void 0 : _a[firstKey] : undefined;
    if (!moniker)
        return _jsx(_Fragment, {});
    return (_jsx(Paragraph, { strong: true, className: 'turingo-subtext', style: { wordBreak: 'break-word', maxWidth: maxWidth }, ellipsis: { rows: 1 }, children: `${moniker === null || moniker === void 0 ? void 0 : moniker.title}` }));
};
export const getMoniker = (user, maxWidth, hideTitle) => {
    var _a;
    const firstKey = (user === null || user === void 0 ? void 0 : user.memberProperties) ? Object.keys(user === null || user === void 0 ? void 0 : user.memberProperties)[0] : undefined;
    const moniker = firstKey ? (_a = user === null || user === void 0 ? void 0 : user.memberProperties) === null || _a === void 0 ? void 0 : _a[firstKey] : undefined;
    const uniquePk = v4();
    if (!moniker)
        return _jsx(_Fragment, {});
    if ((moniker === null || moniker === void 0 ? void 0 : moniker.type) == 'geo:city:id') {
        return (_jsx(Paragraph, { className: 'turingo-subtext', style: { wordBreak: 'break-word' }, ellipsis: { rows: 1 }, children: `${(moniker === null || moniker === void 0 ? void 0 : moniker.value).name}, ${(moniker === null || moniker === void 0 ? void 0 : moniker.value).countryName}` }));
    }
    if ((moniker === null || moniker === void 0 ? void 0 : moniker.type) == 'integer') {
        return (_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [!hideTitle && (_jsx(Paragraph, { className: 'turingo-subtext', strong: true, style: { wordBreak: 'break-word', marginRight: 4 }, ellipsis: { rows: 1 }, children: ` ${moniker === null || moniker === void 0 ? void 0 : moniker.title}` })), _jsx(Paragraph, { className: 'turingo-subtext', style: { wordBreak: 'break-word' }, ellipsis: { rows: 1 }, children: ` ${moniker === null || moniker === void 0 ? void 0 : moniker.value}` })] }));
    }
    if ((moniker === null || moniker === void 0 ? void 0 : moniker.type) == 'string:YYYY-MM-DD:maskyear') {
        return (_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [!hideTitle && (_jsx(Paragraph, { className: 'turingo-subtext', strong: true, style: { wordBreak: 'break-word', marginRight: 4 }, ellipsis: { rows: 1 }, children: ` ${moniker === null || moniker === void 0 ? void 0 : moniker.title}` })), _jsx(Paragraph, { className: 'turingo-subtext', style: { wordBreak: 'break-word' }, ellipsis: { rows: 1 }, children: `${dayjs(moniker === null || moniker === void 0 ? void 0 : moniker.value).format('YYYY')}` })] }));
    }
    if ((moniker === null || moniker === void 0 ? void 0 : moniker.type) == 'string:YYYY-MM-DD') {
        return (_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [!hideTitle && (_jsx(Paragraph, { className: 'turingo-subtext', strong: true, style: { wordBreak: 'break-word', marginRight: 4 }, ellipsis: { rows: 1 }, children: ` ${moniker === null || moniker === void 0 ? void 0 : moniker.title}` })), _jsx(Paragraph, { className: 'turingo-subtext', style: { wordBreak: 'break-word' }, ellipsis: { rows: 1 }, children: `${dayjs(moniker === null || moniker === void 0 ? void 0 : moniker.value).format('D MMM YYYY')}` })] }));
    }
    if ((moniker === null || moniker === void 0 ? void 0 : moniker.type) == 'array:opt:multi') {
        const tags = (moniker === null || moniker === void 0 ? void 0 : moniker.value).split(',');
        return (_jsx(Paragraph, { className: 'turingo-subtext', style: { wordBreak: 'break-word' }, ellipsis: { rows: 1 }, children: _jsx(MonikerComponent, { maxDivSize: maxWidth, uniquePk: uniquePk, tags: tags }, tags.length) }));
    }
    if ((moniker === null || moniker === void 0 ? void 0 : moniker.type) == 'boolean') {
        return (_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [!hideTitle && (_jsx(Paragraph, { className: 'turingo-subtext', strong: true, style: { wordBreak: 'break-word', marginRight: 4 }, ellipsis: { rows: 1 }, children: ` ${moniker === null || moniker === void 0 ? void 0 : moniker.title}` })), (moniker === null || moniker === void 0 ? void 0 : moniker.value) == 'true' ? (_jsx(CheckOne, { style: { display: 'flex' }, theme: "outline", size: "14" })) : (_jsx(CloseOne, { style: { display: 'flex' }, theme: "outline", size: "14" }))] }));
    }
    return (_jsx(Paragraph, { className: 'turingo-subtext', style: { wordBreak: 'break-word' }, ellipsis: { rows: 1 }, children: `${moniker.value}` }));
};
export const PostAuthor = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { author, postKey, date, size = 'medium', type = 'post', creation, board } = props;
    const language = useAppSelector((state) => state.ui.language);
    const postContext = useContext(PostContext);
    const { isFeed } = postContext;
    const { cm_pk } = useCommunity();
    const { p_pk: pr_pk } = useParams();
    let { b_pk } = postContext;
    const badges = (_a = author === null || author === void 0 ? void 0 : author.teams) === null || _a === void 0 ? void 0 : _a.map((t) => t.badge);
    const breakpoints = useBreakpoints();
    if (isFeed)
        b_pk = ((_b = creation === null || creation === void 0 ? void 0 : creation.boardSelected) === null || _b === void 0 ? void 0 : _b.publicKey) ? (_c = creation === null || creation === void 0 ? void 0 : creation.boardSelected) === null || _c === void 0 ? void 0 : _c.publicKey : 'b_pk';
    const moniker = useCallback((user, maxWidth) => {
        return getMoniker(user, maxWidth);
    }, [author]);
    return (_jsxs("div", { style: { display: 'flex', alignItems: !date && !postKey ? 'center' : 'start', width: 'calc(100% - 54px)' }, children: [_jsx(AvatarTuringo, { size: size == 'medium' ? 24 : 16, style: {
                    height: size == 'medium' ? 48 : 40,
                    width: size == 'medium' ? 48 : 40,
                    lineHeight: size == 'medium' ? '48px' : '40px',
                    marginRight: breakpoints.isMobile ? 16 : 8,
                }, name: author.isDeleted ? 'Cuenta eliminada' : author === null || author === void 0 ? void 0 : author.name, avatar: author === null || author === void 0 ? void 0 : author.avatarUrl }), _jsxs("div", { style: { lineHeight: '16px', display: 'flex', flexDirection: 'column', width: 'inherit' }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', marginBottom: creation ? 4 : 0 }, children: [_jsx(LinkUnderline, { href: !author.isDeleted && Router.Path.user_details({ u_pk: author.publicKey, cm_pk }), style: { color: "var(--neutral-13)", maxWidth: breakpoints.isMobile ? 'calc(100vw - 130px)' : 300 }, children: _jsx(Paragraph, { className: 'turingo-text bold', style: {
                                        lineHeight: '18px',
                                        wordBreak: 'break-word',
                                    }, ellipsis: { rows: 1 }, children: author.isDeleted ? 'Cuenta eliminada' : author.name }) }), badges &&
                                badges.map((b) => {
                                    if (b && b.title) {
                                        return (_jsxs(TuringoTag, { style: { marginLeft: 8 }, kind: "tag", color: "neutral", size: "small", children: [capitalizeFirstLetter(b.title), ' '] }, b.title));
                                    }
                                })] }), postKey && moniker(author), (creation === null || creation === void 0 ? void 0 : creation.loading) && _jsx(Skeleton, { title: false, active: true, paragraph: { rows: 1 }, style: { marginBottom: 0 } }), !postKey && isFeed && (creation === null || creation === void 0 ? void 0 : creation.onBoard) && !creation.isEdit && !creation.loading && (_jsx(Button, { style: {
                            fontSize: breakpoints.isMobile ? 14 : 12,
                            height: 24,
                            lineHeight: '12px',
                            padding: '1px 8px 1px 0px',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            borderRadius: 4,
                            alignItems: 'center',
                        }, onClick: creation.onBoard, icon: _jsx(DownOutlined, { style: {
                                marginLeft: 4,
                                fontSize: 10,
                                display: 'flex',
                            } }), children: !((_d = creation.boardSelected) === null || _d === void 0 ? void 0 : _d.publicKey) ? strings.screens.community.publicIn : (_e = creation.boardSelected) === null || _e === void 0 ? void 0 : _e.name })), !postKey && (!(creation === null || creation === void 0 ? void 0 : creation.onBoard) || !isFeed || (creation === null || creation === void 0 ? void 0 : creation.isEdit)) && !creation.loading && (_jsx("div", { style: {
                            fontSize: 14,
                        }, children: `${strings.screens.community.publicIn} ${(_f = creation.boardSelected) === null || _f === void 0 ? void 0 : _f.name}` })), date && type == 'post' && postKey && !pr_pk && board && (_jsx(LinkUnderline, { type: 'secondary', href: Router.Path.community_post_details({ cm_pk, b_pk: board ? board.publicKey : b_pk, p_pk: postKey }), style: { fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: 'var(--neutral-7)' }, children: board && strings.formatString(strings.screens.community.postedIn, board.name, dayjs(date).locale(language).fromNow()) })), date && type == 'post' && !isFeed && pr_pk && (_jsx(Typography.Text, { type: 'secondary', style: { fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: 'var(--neutral-7)' }, children: dayjs(date).locale(language).fromNow() })), date && type == 'post' && !isFeed && !pr_pk && (_jsx(LinkUnderline, { type: 'secondary', href: Router.Path.community_post_details({ cm_pk, b_pk: board ? board.publicKey : b_pk, p_pk: postKey }), style: { fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: 'var(--neutral-7)' }, children: dayjs(date).locale(language).fromNow() })), date && type != 'post' && (_jsx("div", { style: { fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: 'var(--neutral-7)' }, children: dayjs(date).locale(language).fromNow() }))] })] }));
};
