import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Divider, Radio, Space, Spin } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import useCommunity from '@hooks/useCommunity';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import { communityEdit } from '@state/actions';
import { API } from '@api/API';
const frequencyToValue = (freq) => {
    if (freq == 7)
        return 'weekly';
    if (freq == 14)
        return 'biweekly';
    if (freq == 30)
        return 'monthly';
    return 'none';
};
export const valueToFrequency = (freq) => {
    if (freq == 'weekly')
        return 7;
    if (freq == 'biweekly')
        return 14;
    if (freq == 'monthly')
        return 30;
    return null;
};
const NotificationScreen = (props) => {
    var _a, _b;
    const { cm_pk } = useCommunity();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const submit = (freq) => {
        setLoading(true);
        dispatch(communityEdit({
            urlParams: { cm_pk },
            bodyParams: {
                digestFrequency: valueToFrequency(freq)
            },
            options: {
                then: {
                    action: () => {
                        setLoading(false);
                        void API.analyticsAction({
                            bodyParams: {
                                action: `community.digest.freq.${freq}`,
                                sender: 'settings',
                            },
                        });
                    }
                }
            }
        }));
    };
    return (_jsxs("div", { className: "lateral-padding", style: { paddingBottom: 16 }, children: [_jsx(Divider, { style: { marginTop: 0 } }), _jsx(Paragraph, { style: { marginBottom: 16 }, children: strings.screens.admin.notifications.description }), _jsx(Spin, { spinning: loading, children: _jsx(Radio.Group, { style: Object.assign({}, (isMobile && { width: '100%' })), onChange: (e) => submit(e.target.value), value: frequencyToValue((_b = (_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.digest) === null || _b === void 0 ? void 0 : _b.freq), className: "turingo-radio-list", children: _jsxs(Space, { direction: "vertical", size: 0, children: [_jsx(Radio, { value: "weekly", children: strings.screens.admin.notifications.options.weekly }), _jsx(Divider, {}), _jsx(Radio, { value: "biweekly", children: strings.screens.admin.notifications.options.biweekly }), _jsx(Divider, {}), _jsx(Radio, { value: "monthly", children: strings.screens.admin.notifications.options.monthly }), _jsx(Divider, {}), _jsx(Radio, { value: "none", children: strings.screens.admin.notifications.options.none })] }) }) })] }));
};
export { NotificationScreen };
