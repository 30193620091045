import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import Meta from 'react-meta-tags';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { BellOutlined } from '@ant-design/icons';
import { Card, Divider } from 'antd';
import strings from '@resources/localization';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { TuringoList } from '@components/list/TuringoList';
import { Router } from '@Router';
import { dynamicTabName } from '@util/string/Functions';
import { NotificationContext } from 'src/SocketComponent';
import { LanguageIcon } from '@components/icons/LanguageIcon';
import { ApparenceIcon } from '@components/icons/ApparenceIcon';
import { RegistrationIcon } from '@components/icons/RegistrationIcon';
import useCommunity from '@hooks/useCommunity';
export const Settings = (props) => {
    const { cm_pk } = useCommunity();
    const history = useHistory();
    const notifications = useContext(NotificationContext);
    const breakpoints = useBreakpoints();
    return (_jsx(ContentLayout, { left: _jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName(strings.screens.settings.browserTitle, notifications) }), _jsx("meta", { name: "description", content: strings.screens.settings.browserDescription })] }), _jsxs(Card, { bordered: false, style: { width: '100%', height: '100%' }, bodyStyle: { padding: 0 }, children: [_jsxs("div", { style: { padding: '16px 24px 0px 24px' }, children: [breakpoints.isMobile && (_jsx("div", { className: "goBack", style: {
                                        display: 'flex',
                                        width: 'fit-content',
                                        alignItems: 'center',
                                        marginBottom: 26,
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        fontWeight: 400,
                                    }, onClick: () => history.goBack(), children: strings.screens.auth.signup.goBack })), _jsx(Paragraph, { strong: true, className: "turingo-text", style: { fontSize: breakpoints.isMobile ? 18 : 16 }, children: strings.screens.settings.title })] }), _jsx(Divider, {}), _jsx(TuringoList, { loading: false, items: [
                                { publicKey: 'language', name: strings.screens.settings.sections.language.title, icon: _jsx(LanguageIcon, { style: { width: '14px', height: '14px', marginRight: 4 } }) },
                                {
                                    publicKey: 'appearance',
                                    name: strings.screens.settings.sections.theme.title,
                                    icon: _jsx(ApparenceIcon, { style: { width: '14px', height: '14px', marginRight: 4 } }),
                                },
                                { publicKey: 'account', name: strings.screens.settings.sections.signup.title, icon: _jsx(RegistrationIcon, { style: { width: '14px', height: '14px', marginRight: 4 } }) },
                                {
                                    publicKey: 'notifications',
                                    name: strings.screens.settings.sections.notifications.title,
                                    icon: _jsx(BellOutlined, { style: { width: '14px', height: '14px', marginRight: 4 } }),
                                },
                            ], onClick: (value) => history.push(Router.Path.settings_detail({ cm_pk, setting_section: value })) })] })] }) }));
};
