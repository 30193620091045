import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button, Divider, Typography } from 'antd';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import strings from '@resources/localization';
import { FormSelectModalItem } from '@components/form/FormSelect/FormSelectModalItem';
import { FooterModal } from '@components/modals/components/FooterModal';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { communityPosts, postList } from '@state/actions';
import { FilterOutlined } from '@ant-design/icons';
import useBreakpoints from '@hooks/useBreakpoints';
import { useParams } from 'react-router';
import useCommunity from '@hooks/useCommunity';
import { TopicDeleteButton } from '@components/content/posts/post_modal/topics/TopicDeleteButton';
const FilterTopic = (props) => {
    const { topics } = props;
    const { cm_pk } = useCommunity();
    const { b_pk = props.b_pk } = useParams();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const [filterModal, setFilterModal] = useState(false);
    const [topicSelected, setTopicSelected] = useState([]);
    const [topicSelectedForm, setTopicSelectedForm] = useState([]);
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoints();
    const onTopicSelected = (topic) => {
        setTopicSelectedForm(topic);
    };
    const onFilter = () => {
        setTopicSelected(topicSelectedForm);
        dispatchFilterByTopic(topicSelectedForm);
        setFilterModal(false);
    };
    const onRemoveFilter = () => {
        setTopicSelected(undefined);
        dispatchFilterByTopic();
    };
    const dispatchFilterByTopic = (topics) => {
        let topicToFilter = { publicKey: 'all' };
        if (topics && topics.length > 0)
            topicToFilter = topics[0];
        if (b_pk == 'feed') {
            communityPosts(Object.assign(Object.assign({ urlParams: {
                    cm_pk,
                } }, (topicToFilter &&
                topicToFilter.publicKey != 'all' &&
                topicToFilter.publicKey != '' && {
                searchParams: {
                    topic: topicToFilter.publicKey,
                },
            })), { headerParams: {
                    page: 'first',
                }, extraParams: {
                    section: 'feed'
                } }));
        }
        else {
            dispatch(postList(Object.assign(Object.assign({ urlParams: { cm_pk, b_pk } }, (topicToFilter &&
                topicToFilter.publicKey != 'all' &&
                topicToFilter.publicKey != '' && {
                searchParams: {
                    topic: topicToFilter.publicKey,
                },
            })), { headerParams: {
                    page: 'first',
                } })));
        }
        dispatch(postList(Object.assign(Object.assign({ urlParams: { cm_pk, b_pk } }, (topicToFilter &&
            topicToFilter.publicKey != 'all' &&
            topicToFilter.publicKey != '' && {
            searchParams: {
                topic: topicToFilter.publicKey,
            },
        })), { headerParams: {
                page: 'first',
            } })));
    };
    return (_jsxs(_Fragment, { children: [_jsx(TuringoModal, { title: strings.screens.boards.discussion.details.filter.description, width: 572, open: filterModal, onOk: onFilter, footer: _jsx(FooterModal, { primary: {
                        action: onFilter,
                        customText: strings.screens.boards.discussion.details.filter.action,
                    } }, 'footer_modal'), onCancel: () => setFilterModal(false), children: _jsx(FormSelectModalItem, { defaultValue: topicSelected, kind: "list", mode: "single", items: topics, afterAction: onTopicSelected }) }), topics.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), isLogged && (_jsxs(_Fragment, { children: [_jsxs("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }, children: [_jsxs("div", { style: { display: 'flex', flexDirection: 'row', alignItems: breakpoints.xxs ? 'start' : 'center', marginRight: 8 }, children: [_jsx(FilterOutlined, { style: { marginRight: 8, fontSize: 16, marginTop: breakpoints.xxs ? 4 : 0 } }), _jsxs(Typography.Paragraph, { style: { marginBottom: 0 }, children: [strings.screens.boards.discussion.details.filter.title, ' '] })] }), _jsx(Button, { onClick: () => setFilterModal(true), children: strings.screens.boards.discussion.details.filter.action })] }), topicSelected && topicSelected.length > 0 && (_jsx("div", { style: { display: 'flex', marginTop: 16 }, children: topicSelected.map((topic) => {
                                    return _jsx(TopicDeleteButton, { title: topic.name, onClick: onRemoveFilter }, topic.publicKey);
                                }) }))] }))] }))] }));
};
export { FilterTopic };
