import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Redirect, useLocation } from 'react-router-dom';
import { useAppSelector } from '@hooks/useStore';
import { Row, Tabs, Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { VideoTwo, GoldMedal, Diamond, Peoples, CooperativeHandshake } from '@icon-park/react';
import { LaptopOutlined, ClockCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import strings from '@resources/localization';
import { EntitiesList } from '@components/content/entity/entity_list/EntitiesList';
import { EntityHero } from '@components/content/entity/entity_hero/EntityHero';
import CourseHero from '@bundle/assets/entity/photo-banner-courses.jpg';
import ForumHero from '@bundle/assets/entity/photo-banner-forums.jpg';
import StoriesHero from '@bundle/assets/entity/photo-banner-stories.jpg';
import { Router } from '@Router';
import useBreakpoints from '@hooks/useBreakpoints';
import queryString from 'query-string';
import useCommunity from '@hooks/useCommunity';
import { Footer } from '@screens/landing/components/Footer/Footer';
import Meta from 'react-meta-tags';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { dynamicTabName } from '@util/string/Functions';
import { useContext } from 'react';
import { NotificationContext } from 'src/SocketComponent';
const { TabPane } = Tabs;
const BoardList = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const { kind } = props;
    const authState = useAppSelector((state) => state.auth.authState);
    const location = useLocation();
    const notifications = useContext(NotificationContext);
    const queryParams = queryString.parse(location.search);
    const { cm_pk } = useCommunity();
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const featuresAssets = {
        course: {
            hero: CourseHero,
            features: [
                { kind: 'antd', Icon: LaptopOutlined },
                { kind: 'iconpark', Icon: VideoTwo },
                { kind: 'iconpark', Icon: GoldMedal },
            ],
        },
        forum: {
            hero: ForumHero,
            features: [
                { kind: 'antd', Icon: ClockCircleOutlined },
                { kind: 'iconpark', Icon: Diamond },
                { kind: 'antd', Icon: PlayCircleOutlined },
            ],
        },
        story: {
            hero: StoriesHero,
            features: [
                { kind: 'antd', Icon: ClockCircleOutlined },
                { kind: 'iconpark', Icon: Peoples },
                { kind: 'iconpark', Icon: CooperativeHandshake },
            ],
        },
    };
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const breakpoints = useBreakpoints();
    const isSmall = breakpoints.isSmall;
    const items = (_d = (_c = (_b = (_a = strings.screens.boards[kind]) === null || _a === void 0 ? void 0 : _a.list) === null || _b === void 0 ? void 0 : _b.hero) === null || _c === void 0 ? void 0 : _c.features) === null || _d === void 0 ? void 0 : _d.map((item, index) => {
        var _a, _b;
        return Object.assign(Object.assign({}, item), { Icon: (_a = featuresAssets[kind]) === null || _a === void 0 ? void 0 : _a.features[index].Icon, kind: (_b = featuresAssets[kind]) === null || _b === void 0 ? void 0 : _b.features[index].kind });
    });
    switch (authState) {
        case 'unauth.expired':
            return _jsx(Redirect, { to: Router.Path.login({}) });
        case 'unauth.rejected':
        case 'unauth.visitor':
        case 'unauth.closed':
            return (_jsxs(_Fragment, { children: [_jsx(Row, { justify: 'center', style: { alignContent: 'center', marginBottom: isSmall ? 0 : 40 }, children: _jsx(EntityHero, { title: (_e = strings.screens.boards[kind]) === null || _e === void 0 ? void 0 : _e.list.hero.title, banner: (_f = featuresAssets[kind]) === null || _f === void 0 ? void 0 : _f.hero, features: items }) }), _jsx("div", { style: { width: '100%', backgroundColor: isDark ? 'black' : '#F5F5F5', paddingBottom: isSmall ? 24 : 40 }, children: _jsxs("div", { style: {
                                maxWidth: 1260,
                                justifyContent: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                padding: breakpoints.isMobile ? '0px 16px' : '0px 24px',
                            }, children: [_jsx(Title, { style: {
                                        textAlign: 'center',
                                        fontSize: 30,
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        marginTop: isSmall ? 0 : 40,
                                        marginBottom: isSmall ? 24 : 40,
                                    }, children: (_g = strings.screens.boards[kind]) === null || _g === void 0 ? void 0 : _g.list.hero.separator }), _jsx(EntitiesList, { kind: kind })] }) }), _jsx(Footer, {})] }));
        case 'auth.normal':
            return (_jsx(ContentLayout, { left: _jsxs(Card, { bordered: false, style: { minHeight: '100%' }, children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName(((_l = (_k = (_j = (_h = community === null || community === void 0 ? void 0 : community.item) === null || _h === void 0 ? void 0 : _h.ui) === null || _j === void 0 ? void 0 : _j.rename) === null || _k === void 0 ? void 0 : _k[kind]) === null || _l === void 0 ? void 0 : _l.plural) || strings.screens.boards[kind].title, notifications) }), _jsx("meta", { name: "description", content: ((_q = (_p = (_o = (_m = community === null || community === void 0 ? void 0 : community.item) === null || _m === void 0 ? void 0 : _m.ui) === null || _o === void 0 ? void 0 : _o.rename) === null || _p === void 0 ? void 0 : _p[kind]) === null || _q === void 0 ? void 0 : _q.plural) || strings.screens.boards[kind].browserDescription })] }), _jsx(Paragraph, { strong: true, style: { marginBottom: 16 }, children: ((_u = (_t = (_s = (_r = community === null || community === void 0 ? void 0 : community.item) === null || _r === void 0 ? void 0 : _r.ui) === null || _s === void 0 ? void 0 : _s.rename) === null || _t === void 0 ? void 0 : _t[kind]) === null || _u === void 0 ? void 0 : _u.plural) || ((_v = strings.general[kind]) === null || _v === void 0 ? void 0 : _v.plural) }), _jsxs(Tabs, { destroyInactiveTabPane: true, style: { width: '100%' }, defaultActiveKey: queryParams.page ? queryParams.page : '1', children: [_jsx(TabPane, { tab: strings.general.board_availables, children: _jsx(EntitiesList, { searchBar: true, kind: kind }) }, "1"), kind != 'story' && (_jsx(TabPane, { tab: strings.general.board_inprogress, children: _jsx(EntitiesList, { following: true, searchBar: true, kind: kind }) }, "2"))] })] }) }));
    }
};
export const Courses = () => {
    return BoardList({ kind: 'course' });
};
export const Forums = () => {
    return BoardList({ kind: 'forum' });
};
export const Stories = () => {
    return BoardList({ kind: 'story' });
};
