import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Alert, Divider, Row, Switch } from 'antd';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useHistory } from 'react-router';
import { Router } from '@Router';
import useBreakpoints from '@hooks/useBreakpoints';
import { Check, Close } from '@icon-park/react';
import { communityMemberDetails, userEdit } from '@state/actions';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { setSound } from '@state/reducers/UserInterface/UserInterfaceReducer';
import { API } from '@api/API';
const NotificationsScreen = () => {
    var _a, _b, _c, _d, _e, _f;
    const history = useHistory();
    const breakpoints = useBreakpoints();
    const dispatch = useAppDispatch();
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const currentUser = useAppSelector((state) => state.auth.currentEntity);
    useEffect(() => {
        void dispatch(communityMemberDetails({ urlParams: { cm_pk: currentCommunity, u_pk: currentUser.publicKey } }));
    }, []);
    const soundSetting = useAppSelector((state) => state.ui.sound);
    const isMobile = breakpoints.isMobile;
    const [loadingChat, setLoadingChat] = useState(false);
    const [loadingDigest, setLoadingDigest] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
    const changeSettings = (kind, checked) => {
        if (kind == 'notifyDigest')
            setLoadingDigest(true);
        if (kind == 'notifyChat')
            setLoadingChat(true);
        if (kind == 'notifyEvent')
            setLoadingEvents(true);
        if (kind == 'notifyMediumWhatsapp')
            setLoadingWhatsapp(true);
        void dispatch(userEdit({
            urlParams: {
                cm_pk: currentCommunity,
            },
            bodyParams: {
                settings: {
                    [kind]: checked,
                },
            },
            extraParams: {
                u_pk: currentUser.publicKey,
            },
            options: {
                then: {
                    action: () => {
                        if (kind == 'notifyDigest') {
                            setLoadingDigest(false);
                            void API.analyticsAction({
                                bodyParams: {
                                    action: checked ? 'notifications.digest.on' : 'notifications.digest.off',
                                    sender: 'settings',
                                },
                            });
                        }
                        if (kind == 'notifyChat') {
                            setLoadingChat(false);
                            void API.analyticsAction({
                                bodyParams: {
                                    action: checked ? 'notifications.chat.on' : 'notifications.chat.off',
                                    sender: 'settings',
                                },
                            });
                        }
                        if (kind == 'notifyEvent') {
                            setLoadingEvents(false);
                            void API.analyticsAction({
                                bodyParams: {
                                    action: checked ? 'notifications.events.on' : 'notifications.events.off',
                                    sender: 'settings',
                                },
                            });
                        }
                        if (kind == 'notifyMediumWhatsapp') {
                            setLoadingWhatsapp(false);
                            void API.analyticsAction({
                                bodyParams: {
                                    action: checked ? 'notifications.whatsapp.on' : 'notifications.whatsapp.off',
                                    sender: 'settings',
                                },
                            });
                        }
                    },
                },
                alerts: {
                    error: strings.alerts.user.edit.failure,
                },
            },
        }));
    };
    if (!currentUser) {
        return _jsx(_Fragment, {});
    }
    else {
        return (_jsxs("div", { className: "lateral-padding", style: { paddingBottom: 16 }, children: [_jsxs("div", { style: { marginTop: 16, display: 'flex', alignItems: 'center' }, children: [_jsx(GoBackButton, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }, type: "default", showText: false, onClick: () => history.push(Router.Path.settings({})) }), _jsx(Paragraph, { strong: true, className: "turingo-text", children: strings.screens.settings.sections.notifications.title })] }), _jsx(Divider, {}), _jsx(Paragraph, { strong: true, className: "turingo-text", style: { marginBottom: 16 }, children: strings.screens.settings.sections.notifications.activity.title }), _jsxs("div", { style: { padding: '0px 24px' }, children: [_jsxs(Row, { justify: 'space-between', style: { marginBottom: 8 }, children: [_jsx("span", { style: { display: 'flex' }, children: _jsx("p", { style: { fontWeight: 500, marginBottom: 0, alignSelf: 'center' }, children: strings.screens.settings.sections.notifications.activity.sound.title }) }), _jsx(Switch, { checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }), checked: soundSetting, loading: false, onClick: (checked) => {
                                        dispatch(setSound(checked));
                                        void API.analyticsAction({
                                            bodyParams: {
                                                action: checked ? 'notifications.sound.on' : 'notifications.sound.off',
                                                sender: 'settings',
                                            },
                                        });
                                    } })] }), _jsx(Row, { style: { marginBottom: isMobile ? 32 : 0 }, children: _jsx("p", { children: strings.screens.settings.sections.notifications.activity.sound.description }) })] }), !isMobile && _jsx(Divider, {}), _jsx(Paragraph, { strong: true, className: "turingo-text", style: { marginBottom: 16 }, children: strings.screens.settings.sections.notifications.email.title }), _jsx(Alert, { style: { marginBottom: 16 }, showIcon: true, message: strings.screens.settings.sections.notifications.email.alert }), _jsxs("div", { style: { padding: '0px 24px' }, children: [_jsxs(Row, { justify: 'space-between', style: { marginBottom: 8 }, children: [_jsx("span", { style: { display: 'flex' }, children: _jsx("p", { style: { fontWeight: 500, marginBottom: 0, alignSelf: 'center' }, children: strings.screens.settings.sections.notifications.email.chat.title }) }), _jsx(Switch, { checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }), checked: (_b = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.settings) === null || _a === void 0 ? void 0 : _a.ntf) === null || _b === void 0 ? void 0 : _b.chat, loading: loadingChat, onClick: (checked) => {
                                        changeSettings('notifyChat', checked);
                                    } })] }), _jsx(Row, { style: { marginBottom: isMobile ? 32 : 0 }, children: _jsx("p", { children: strings.screens.settings.sections.notifications.email.chat.description }) }), !isMobile && _jsx(Divider, {}), _jsxs(Row, { justify: 'space-between', style: { marginBottom: 8 }, children: [_jsx("span", { style: { display: 'flex' }, children: _jsx("p", { style: { fontWeight: 500, marginBottom: 0, alignSelf: 'center' }, children: strings.screens.settings.sections.notifications.email.event.title }) }), _jsx(Switch, { checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }), checked: (_d = (_c = currentUser === null || currentUser === void 0 ? void 0 : currentUser.settings) === null || _c === void 0 ? void 0 : _c.ntf) === null || _d === void 0 ? void 0 : _d.event, loading: loadingEvents, onClick: (checked) => {
                                        changeSettings('notifyEvent', checked);
                                    } })] }), _jsx(Row, { style: { marginBottom: isMobile ? 32 : 0 }, children: _jsx("p", { children: strings.screens.settings.sections.notifications.email.event.description }) }), !isMobile && _jsx(Divider, {}), _jsxs(Row, { justify: 'space-between', style: { marginBottom: 8 }, children: [_jsx("span", { style: { display: 'flex' }, children: _jsx("p", { style: { fontWeight: 500, marginBottom: 0, alignSelf: 'center' }, children: strings.screens.settings.sections.notifications.email.digest.title }) }), _jsx(Switch, { checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }), checked: (_f = (_e = currentUser === null || currentUser === void 0 ? void 0 : currentUser.settings) === null || _e === void 0 ? void 0 : _e.ntf) === null || _f === void 0 ? void 0 : _f.digest, loading: loadingDigest, onClick: (checked) => {
                                        changeSettings('notifyDigest', checked);
                                    } })] }), _jsx(Row, { style: { marginBottom: isMobile ? 32 : 0 }, children: _jsx("p", { children: strings.screens.settings.sections.notifications.email.digest.description }) })] })] }));
    }
};
export { NotificationsScreen };
