import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Meta from 'react-meta-tags';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { Card } from 'antd';
import strings from '@resources/localization';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { dynamicTabName } from '@util/string/Functions';
import { NotificationContext } from 'src/SocketComponent';
import { Digest } from './sections/Digest';
import { Event } from './sections/Event';
import { Chat } from './sections/Chat';
export const DebugDetail = (props) => {
    const { debug_section } = useParams();
    const notifications = useContext(NotificationContext);
    return (_jsx(ContentLayout, { left: _jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName(strings.screens.settings.browserTitle, notifications) }), _jsx("meta", { name: "description", content: strings.screens.settings.browserDescription })] }), _jsxs(Card, { bordered: false, style: { width: '100%', height: '100%' }, bodyStyle: { padding: 0 }, children: [debug_section == 'digest' && _jsx(Digest, {}), debug_section == 'event' && _jsx(Event, {}), debug_section == 'chat' && _jsx(Chat, {})] })] }) }));
};
